import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate, ListLoader } from 'main/components/list_loader';
import { tasksApi, TaskData } from '../api/simple_api';
import { operationName } from './tasks';

let template = require('raw-loader!../../templates/my_tasks.html').default;

class MyTasksScreen implements ListLoaderDelegate<TaskData, TaskData> {
    loader: ListLoader<TaskData, TaskData>;

    operationName = operationName;

    noItemsText = i18n.t('You have no tasks left.')();

    fetch(params: ListRequestParams) {
        return tasksApi.list({ mine: true, for_user_type: null, ...params });
    }

    getEditUrl(entity: TaskData) {
        return '';
    }

    instantiate(data: TaskData) {
        return data;
    }

    remove(id: string) {
        return Promise.reject(null);
    }

    canRemove(entity: TaskData) {
        return false;
    }
}

export let myTasks = { name: 'my-tasks', viewModel: MyTasksScreen, template: template };

ko.components.register(myTasks.name, myTasks);
