import * as ko from 'knockout';

import { OrganizationalRegion } from '../models/organizational_region';
import { OrganizationalRegionData, orgRegionApi } from '../api/org_regions';
import { createWithComponent } from '../utils/ko_utils';
import { BaseEditScreen, BaseEditParams } from './base_edit';

let template = require('raw-loader!../../templates/organizational_region_edit.html').default;

class OrgRegionEditScreen extends BaseEditScreen<OrganizationalRegionData, OrganizationalRegion> {
  constructor(
    params: BaseEditParams<OrganizationalRegionData>,
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ api: orgRegionApi, focus: true }, params, componentInfo);
  }

  instantiate(data: OrganizationalRegionData) {
    return new OrganizationalRegion(data);
  }
}

export let orgRegionEdit = {
  name: 'org-region-edit',
  viewModel: createWithComponent(OrgRegionEditScreen),
  template: template,
};

ko.components.register(orgRegionEdit.name, orgRegionEdit);
