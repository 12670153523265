import * as ko from 'knockout';

import 'chart.js/auto';
import { Chart, ChartDataset, ChartOptions } from 'chart.js';

Chart.register({
    id: 'white-background',
    beforeDraw: function (chart, args, options) {
        var ctx = chart.ctx;

        ctx.save();
        ctx.fillStyle = '#FFFFFF';
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
        ctx.restore();
    }
});

export interface BarChartConfig {
    title?: string;
    xTitle?: string;
    labels: string[];
    fontSize: number;
    datasets: {
        label: string;
        stack?: string;
        bar_type: 'fill' | 'outline';
        color_key: string;
        color_range: number;
        data: number[];
        color?: string;
    }[];
    options?: ChartOptions;
    horizontal?: boolean;
}

function interpolateColors(start: number[], end: number[], n: number): string[] {
    let res: string[] = [];

    for (let i = 0; i < n; i++) {
        let color = '#';
        for (let j = 0; j < 3; j++) {
            let part = Math.round(start[j] + (end[j] - start[j]) * (i / Math.max(1, n - 1))).toString(16);
            if (part.length < 2) {
                part = '0' + part;
            }
            color += part;
        }
        res.push(color);
    }

    return res;
}

function shade(color: number[], p: number) {
    return color.map((x) => (0xff - x) * p + x);
}

function setup(element: Element, config: BarChartConfig) {
    let seen: { [key: string]: boolean }[] = [{}, {}, {}, {}];
    let nColors = [0, 0, 0, 0];
    for (let ds of config.datasets) {
        if (!seen[ds.color_range][ds.color_key]) {
            seen[ds.color_range][ds.color_key] = true;
            nColors[ds.color_range]++;
        }
    }

    let colors = [
        interpolateColors(
            [0x7d, 0x93, 0x3c],
            shade([0x7d, 0x93, 0x3c], nColors[0] < 3 ? 0.5 : 0.75),
            nColors[0]
        ),
        interpolateColors(
            [0x50, 0x28, 0xa],
            shade([0x50, 0x28, 0xa], nColors[1] < 3 ? 0.5 : 0.75),
            nColors[1]
        ),
        interpolateColors(
            [0xe6, 0x51, 0x00],
            shade([0xe6, 0x51, 0x00], nColors[2] < 3 ? 0.5 : 0.75),
            nColors[2]
        ),
        interpolateColors(
            [0x57, 0x82, 0xab],
            shade([0x57, 0x82, 0xab], nColors[3] < 3 ? 0.5 : 0.75),
            nColors[3]
        ),
    ];

    let idx = [0, 0, 0, 0];
    let assigned: { [key: string]: number }[] = [{}, {}, {}, {}];
    let datasets = config.datasets.map((ds) => {
        if (assigned[ds.color_range][ds.color_key] === undefined) {
            assigned[ds.color_range][ds.color_key] = idx[ds.color_range];
            idx[ds.color_range] = (idx[ds.color_range] + 1) % colors[ds.color_range].length;
        }
        let color = ds.color || colors[ds.color_range][assigned[ds.color_range][ds.color_key]];

        let res: ChartDataset<'bar'>;
        if (ds.bar_type === 'outline') {
            res = { borderColor: color, borderWidth: 2, backgroundColor: 'transparent', ...ds };
        } else {
            res = { backgroundColor: color, ...ds };
        }
        res.maxBarThickness = 50;

        return res;
    });

    const options = config.options || {
        plugins: {
            tooltip: {
                callbacks: {
                    afterTitle: (context: any) => {
                        let sum = 0;
                        for (const item of context) {
                            for (const ds of item.chart.data.datasets) {
                                const value = ds.data[item.dataIndex];
                                if (
                                    value !== null &&
                                    ds.stack === item.chart.data.datasets[item.datasetIndex].stack
                                ) {
                                    sum += value;
                                }
                            }
                        }
                        return sum.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });
                    },
                    label: (context: any) => {
                        let dataset = context.dataset;
                        let category = dataset.label;
                        let value = dataset.data[context.dataIndex];
                        let formatted = value.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });
                        return category + ': ' + formatted;
                    },
                },
            },
            title: {
                display: true,
                font: {
                    size: Math.floor(config.fontSize * 1.2),
                },
                color: APP_CONFIG.PRIMARY_COLOR,
                text: config.title,
            },
            legend: {
                display: datasets.length > 1,
                labels: {
                    color: 'rgba(0, 0, 0, 0.87)',
                    font: { size: config.fontSize },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: config.xTitle,
                    font: { size: config.fontSize },
                },
                ticks: {
                    font: { size: config.fontSize },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: config.title,
                    font: { size: config.fontSize },
                },
                ticks: {
                    font: { size: config.fontSize },
                },
            },
        },
    };
    if (config.horizontal) {
        config.options['indexAxis'] = 'y';
    }

    return new Chart(<HTMLCanvasElement>element, {
        type: 'bar',
        data: {
            labels: config.labels,
            datasets,
        },
        options,
    });
}

ko.bindingHandlers['barChart'] = {
    init: (element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) => {
        renderGraph(element, valueAccessor);
    },
    update: (element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) => {
        renderGraph(element, valueAccessor);
    },
};

function renderGraph(element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) {
    if ((element as any)['chart']) {
      (element as any)['chart'].destroy();
    }
    const chart = setup(element, ko.unwrap(valueAccessor()));
    (element as any)['chart'] = chart;
}