import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { PortofolioItem } from '../models/portofolio_item';
import { session } from '../session';
import { PortofolioItemData, portofolioItemApi } from '../api/portofolio_items';
import { addChangelogListAction, addChangelogAction } from './changelog';
import { Action } from '../components/basic_widgets';

let template = require('raw-loader!../../templates/portofolio_items.html').default;

class PortofolioItemsScreen implements ListLoaderDelegate<PortofolioItemData, PortofolioItem> {
    canCreate = session.isAtLeastEditor();

    listActions = addChangelogListAction('portofolio_item');

    fetch(params: ListRequestParams) {
        return portofolioItemApi.list(params);
    }

    instantiate(data: PortofolioItemData) {
        return new PortofolioItem(data);
    }

    getName(entity: PortofolioItem) {
        return entity.name();
    }

    getEditUrl(entity: PortofolioItem): string {
        return entity.editUrl;
    }

    remove(id: string) {
        return portofolioItemApi.remove(id);
    }

    canRemove(entity: PortofolioItem) {
        return entity.canEdit();
    }

    getActions(entity: PortofolioItem): Action[] {
        return addChangelogAction('portofolio_item', entity.id());
    }
}

export let portofolioItems = {
    name: 'portofolio_items',
    viewModel: PortofolioItemsScreen,
    template: template,
};

ko.components.register(portofolioItems.name, portofolioItems);