import { request, listRequest, requestRaw, makeDefaultApi, listParamsToQueryString, ListRequestParams } from 'main/api/request';
import { NameI18nData } from 'main/api/names';
import { UserDetailData } from 'main/api/users';
import { TaskUserType } from './tasks';
import { I18nText } from 'main/i18n_text';
import { CountryData } from 'main/api/countries';
import { SeasonData } from 'main/api/seasons';
import { ProducerData, BuyerData } from 'main/api/organizations';
import { PartnerCropVarietyData, CropVarietyData } from 'main/api/crop_varieties';
import { UserLogMixinData } from 'main/api/changelog';
import { ProjectData } from 'main/api/projects';
import { PortofolioItemData } from 'main/api/portofolio_items';

export interface CropData {
    id: string;
    name_json: I18nText;
    category: NameI18nData;
}

export const cropsApi = makeDefaultApi<CropData, { ids?: string[] }>('crops');

export const cropVarietyApi = makeDefaultApi<CropVarietyData, { crop_ids?: string[] }>('crop_varieties');

export interface PartnerLicenseData {
    id: string;
    crop_variety: PartnerCropVarietyData;
    royalty: number;
    start_year: number;
    end_year: number;
    support_by_sfsa: boolean;
}

export interface LicenseData extends UserLogMixinData {
    id: string;
    crop_variety: CropVarietyData;
    producer: ProducerData;
    royalty: number;
    start_year: number;
    end_year: number;
    support_by_sfsa: boolean;
    portofolio_items: PortofolioItemData[];
}

export const licenseApi = {
    licensedVarieties(params: { season_year: number, producer: ProducerData }): Promise<NameI18nData[]> {
        return request('POST', '/api/licenses/licensed_varieties/', params);
    },

    ...makeDefaultApi<LicenseData | PartnerLicenseData, { producer_ids?: string[], breeder_ids?: string[], from_year?: number, to_year?: number }, LicenseData>('licenses')
};

export interface BuyingFrequencyData {
    id: string;
    crop_variety_type: NameI18nData;
    frequency: number;
}

export interface AssumptionData extends UserLogMixinData {
    id: string;
    crop: NameI18nData;
    country: CountryData;
    year: number;
    month: number;
    avg_plot_size: number | string;
    percentage_of_male_farmers: number | string;
    percentage_of_female_farmers: number | string;
    annual_crop_area_grown: number | string;
    seed_planting_density: number | string;
    seed_production: number | string;
    seed_imports: number | string;
    seed_price: number | string;
    early_gen_seed_price: number | string;
    crop_price: number | string;
    gross_profit: number | string;
    baseline_yield: number | string;
    yield_gain: number | string;
    check_variety: CropVarietyData;
    comment: string;
    buying_frequencies: BuyingFrequencyData[]; // not present in list
    complete?: boolean; // read-only
}

export interface AssumptionFilterData {
    country: string;
    crop: string;
    complete: 'yes' | 'no' | 'all';
    min_year: number;
    max_year: number;
    history: boolean;
}

export const assumptionApi = {
    exportList(params: AssumptionFilterData): Promise<Blob> {
        return requestRaw('GET', '/api/assumptions/export/?' + listParamsToQueryString(params as {}));
    },

    retrieveRecord(id: string, history: boolean): Promise<AssumptionData> {
        return request('GET', '/api/assumptions/' + id + '/?history=' + history);
    },

    ...makeDefaultApi<AssumptionData, AssumptionFilterData>('assumptions')
};

export interface ProductionData extends UserLogMixinData {
    id: string;
    country: CountryData;
    crop_variety: PartnerCropVarietyData;
    producer: ProducerData;
    project?: ProjectData;
    portofolio_item: PortofolioItemData;
    season_year: number;
    season: SeasonData;
    quantity: number | string;
    quantity_forecast: number | string;
    quantity_early_gen: number | string;
    quantity_early_gen_forecast: number | string;
    area_grown_certified_seeds: number | string;
    area_grown_egs: number | string;
    area_grown_certified_seeds_forecast: number | string;
    area_grown_egs_forecast: number | string;
    comment: string;
    task_id?: string; // write-only
    validated: boolean; // write-only
    validated_by?: string; // read-only
    validated_at?: string; // read-only
    sales: ProductionSaleData[]; // not present in list calls
    total_sales?: number; // read-only, present only in list calls
}

export interface ProductionSaleData {
    id: string;
    buyer: BuyerData;
    sale_date: string; // date in string format
    quantity_certified_seeds_forecast: number | string;
    quantity_certified_seeds_actual: number | string;
    quantity_early_gen_forecast: number | string;
    quantity_early_gen_actual: number | string;
    price_certified_seeds_actual: number | string;
    price_early_gen_actual: number | string;
    price_certified_seeds_forecast: number | string;
    price_early_gen_forecast: number | string;
    yield_gain: number | string;
    comment: string;
    validated: boolean; // write-only
    validated_by?: string; // read-only
    validated_at?: string; // read-only
    order_in_production: number;
}

export const productionApi = {
    exportList(params: SalesFiltersData): Promise<Blob> {
        return requestRaw('GET', '/api/productions/export/?' + listParamsToQueryString(params as {}));
    },

    ...makeDefaultApi<ProductionData, SalesFiltersData>('productions')
};

export interface SalesFiltersData {
    country: string;
    crop: string;
    crop_variety: string;
    producer: string;
    buyer: string;
    min_year: number;
    max_year: number;
    exclude_forecasts: boolean;
    season: string;
    portofolio_item: string;
}

export interface SaleData extends UserLogMixinData {
    id: string;
    country: CountryData;
    crop_variety: PartnerCropVarietyData;
    seller: ProducerData;
    project?: ProjectData;
    portofolio_item: PortofolioItemData;
    buyer: BuyerData;
    sale_date: string; // date in string format
    quantity_certified_seeds_forecast: number | string;
    quantity_certified_seeds_actual: number | string;
    quantity_early_gen_forecast: number | string;
    quantity_early_gen_actual: number | string;
    price_certified_seeds_actual: number | string;
    price_early_gen_actual: number | string;
    price_certified_seeds_forecast: number | string;
    price_early_gen_forecast: number | string;
    readonly total_sales?: number; // read-only, present only in list calls
    yield_gain: number | string;
    comment: string;
    task_id?: string; // write-only
    validated: boolean; // write-only
    validated_by?: string; // read-only
    validated_at?: string; // read-only
}

export const saleApi = {
    listImports(params: ListRequestParams & SalesFiltersData): Promise<SaleData[]> {
        return listRequest('/api/sales/list_imports/', params);
    },

    exportList(params: SalesFiltersData): Promise<Blob> {
        return requestRaw('GET', '/api/sales/export/?' + listParamsToQueryString(params as {}));
    },

    ...makeDefaultApi<SaleData, SalesFiltersData>('sales')
};

export interface TaskData {
    id: string;
    season_year: number;
    season: SeasonData;
    operation: 'production' | 'sale';
    target: 'forecast' | 'actual_data';
    producer: ProducerData;
    buyer: BuyerData;
    user: UserDetailData;
    crop_variety: PartnerCropVarietyData;
    production_id: string;
    sale_id: string;
    closed: boolean;
}

export interface TasksFiltersParams {
    mine?: boolean;
    for_user_type: TaskUserType;
}

export const tasksApi = {
    incomplete(): Promise<TaskData[]> {
        return listRequest('/api/tasks/incomplete/', {});
    },

    ...makeDefaultApi<TaskData, TasksFiltersParams>('tasks')
};

export interface SeedTreatmentData extends UserLogMixinData {
    id: string;
    name: string;
    crop: NameI18nData;
    owner: ProducerData;
    yield_gain: string;
    application_rate: string;
}

export interface SeedTreatmentFiltersData {
    crop?: string;
}

export const seedTreatmentApi = makeDefaultApi<SeedTreatmentData, SeedTreatmentFiltersData>('seed_treatments');

export interface SeedTreatmentSalesFiltersData {
    country: string;
    crop: string;
    seed_treatment: string;
    producer: string;
    buyer: string;
    min_year: number;
    max_year: number;
    portofolio_item: string;
}

export interface SeedTreatmentSaleData extends UserLogMixinData {
    id: string;
    country: CountryData;
    seed_treatment: SeedTreatmentData;
    seller: ProducerData;
    buyer: BuyerData;
    project?: ProjectData;
    portofolio_item: PortofolioItemData;
    season_year: number;
    season: SeasonData;
    quantity: string;
    quantity_forecast: string;
    price: string;
    price_forecast: string;
}

export const seedTreatmentSaleApi = makeDefaultApi<SeedTreatmentSaleData, SeedTreatmentSalesFiltersData>('seed_treatment_sales');

