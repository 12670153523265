import * as ko from 'knockout';

import i18n from 'main/i18n';
import { BaseForm } from 'main/screens/base_form';
import { Sale } from '../models/sale';
import { tasksApi } from '../api/simple_api';
import { createWithComponent } from 'main/utils/ko_utils';
import { Production } from '../models/production';
import { all } from 'main/utils';
import { deepEquals } from 'main/utils/deep_equals';
import { confirmNavigation, clearConfirmNavigation } from 'main/utils/routes';
import { session } from 'main/session';

let template = require('raw-loader!../../templates/tasks_enter.html').default;

class TasksEnterScreen extends BaseForm<{}> {
    entities = ko.observableArray<Production | Sale>();
    lastSaveData: {}[] = [];
    errorPresent = ko.observable(false);

    isBuyer = session.isBuyer();

    constructor(params: {}, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super(params);

        let promise = tasksApi.incomplete().then(tasks => {
            this.entities(tasks.map(data => {
                if (data.operation === 'sale') {
                    return new Sale(undefined, data);
                } else {
                    return new Production(undefined, data);
                }
            }));
            this.lastSaveData = this.entities().map(entity => entity.toData());
        });
        this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));

        confirmNavigation(
            i18n.t('There are unsaved changes. If you leave the page you will lose them. Are you sure you want to continue?')(),
            this.hasUnsavedChanges
        );
    }

    dispose() {
        clearConfirmNavigation();

        for (let entity of this.entities()) {
            if (entity instanceof Sale) {
                entity.dispose();
            }
        }
    }

    private hasUnsavedChanges = () => {
        let entities = this.entities();
        for (let i = 0; i < entities.length; i++) {
            if (!deepEquals(this.lastSaveData[i], entities[i].toData())) {
                return true;
            }
        }

        return false;
    }

    save = () => {
        this.saving(true);

        let promises: Promise<boolean>[] = [];

        let entities = this.entities();
        for (let i = 0; i < entities.length; i++) {
            let entity = entities[i];

            if (!deepEquals(this.lastSaveData[i], entity.toData())) {
                if (!this.validateLocal(entity)) {
                    promises.push(Promise.resolve(false));
                    continue;
                }

                let savePromise = entity.saveRequest().then((res) => {
                    if (res.isValid) {
                        entity.id(res.entityId);
                        this.lastSaveData[i] = entity.toData();
                        return true;
                    } else {
                        this.applyModelServerErrors(entity, res.errors);
                        return false;
                    }
                });
                promises.push(savePromise);
            }
        }

        Promise.all(promises).then((oks) => {
            if (all(oks)) {
                this.close();
            } else {
                this.errorPresent(true);
                setTimeout(() => {
                    this.errorPresent(false);
                }, 5000);
            }
            this.saving(false);
        }).catch((err) => {
            console.error(err);
            this.saving(false);
        });
    }
}

export let tasksEnter = { name: 'tasks-enter', viewModel: createWithComponent(TasksEnterScreen), template: template };

ko.components.register(tasksEnter.name, tasksEnter);
