import * as ko from 'knockout';

ko.bindingHandlers['percentBorder'] = {
    update: (element: HTMLElement, valueAccessor: () => KnockoutObservable<number> | number) => {
        let perc = ko.unwrap(valueAccessor());

        if (perc === null || perc === undefined) {
            element.style.backgroundColor = '';
            element.style.backgroundImage = '';
            return;
        }

        perc = Math.max(0, Math.min(perc, 1)); // force between 0 and 1

        let degrees = 360 * perc;

        let gradient2 = 'linear-gradient(90deg, ' + APP_CONFIG.ACCENT_COLOR_LIGHT + ' 50%, transparent 50%)';

        let gradient1: string;
        if (degrees > 180) {
            gradient1 = 'linear-gradient(' + (degrees - 90) + 'deg, transparent 50%, ' + APP_CONFIG.ACCENT_COLOR + ' 50%)';
        } else {
            gradient1 = 'linear-gradient(' + (degrees + 90) + 'deg, transparent 50%, ' + APP_CONFIG.ACCENT_COLOR_LIGHT + ' 50%)';
        }

        element.style.backgroundColor = APP_CONFIG.ACCENT_COLOR;
        element.style.backgroundImage = gradient1 + ',' + gradient2;
    }
};
