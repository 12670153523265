import { NameData, NameI18nData } from 'main/api/names';
import { requestWithValidation, ValidationResult } from 'main/api/request';
import { UserData } from 'main/api/users';

export interface TaskProducerData {
    producer: NameData;
    crop_varieties: NameI18nData[];
}

export interface TaskBuyerData {
    buyer: NameData;
    crop_varieties: NameI18nData[];
}

export interface TaskBulkCreateData {
    user: UserData;
    country: NameData;
    season_year: number;
    season: NameI18nData;
    operation: 'production' | 'sale';
    target: 'forecast' | 'actual_data';
    producers: TaskProducerData[];
    buyers: TaskBuyerData[];
}

export type TaskUserType = 'staff' | 'producer' | 'buyer';

export function bulkCreateTasks(data: TaskBulkCreateData): Promise<ValidationResult> {
    return requestWithValidation('POST', '/api/tasks/bulk_create/', data);
}

export function closeCompleted(forUserType: TaskUserType): Promise<ValidationResult> {
    return requestWithValidation('POST', '/api/tasks/close_completed/', { for_user_type: forUserType });
}

export function setClosed(id: string, closed: boolean): Promise<ValidationResult> {
    return requestWithValidation('POST', '/api/tasks/' + id + '/set_closed/', { closed });
}

export function sendNotifications(forUserType: TaskUserType): Promise<ValidationResult> {
    return requestWithValidation('POST', '/api/tasks/send_notifications/', { for_user_type: forUserType });
}

export function sendNotification(taskId: string, forUserType: TaskUserType): Promise<ValidationResult> {
    return requestWithValidation('POST', '/api/tasks/' + taskId + '/send_notification/', { for_user_type: forUserType });
}
