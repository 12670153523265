import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate, ListFilter } from 'main/components/list_loader';
import { SeedTreatmentSaleData, seedTreatmentSaleApi, SeedTreatmentSalesFiltersData, SeedTreatmentData, seedTreatmentApi, cropsApi } from '../api/simple_api';
import { SeedTreatmentSale } from '../models/seed_treatment_sale';
import { session } from 'main/session';
import { updateLocationWithQueryString, extractId } from 'main/utils';
import { getSeedTreatmentSearchConfig, getBuyerSearchConfig, getCropSearchConfig } from "../models/helpers/search_configs";
import { NameI18nData } from 'main/api/names';
import { translate } from 'main/i18n_text';
import { CountryData, countriesApi } from 'main/api/countries';
import { getCountrySearchConfig, getPISearchConfig, getProducerSearchConfig } from 'main/components/configs/search_configs';
import { ProducerData, BuyerData, producersApi, buyersApi } from 'main/api/organizations';
import { PortofolioItemData, portofolioItemApi } from 'main/api/portofolio_items';
import { addChangelogListAction, addChangelogAction } from 'main/screens/changelog';
import { Action } from 'main/components/basic_widgets';

let template = require('raw-loader!../../templates/seed_treatment_sales.html').default;

export class SeedTreatmentSalesFilters {
    country = ko.observable<CountryData>();
    crop = ko.observable<NameI18nData>(null);
    seedTreatment = ko.observable<SeedTreatmentData>(null);
    producer = ko.observable<ProducerData>(null);
    buyer = ko.observable<BuyerData>(null);
    minYear = ko.observable<string>();
    maxYear = ko.observable<string>();
    portofolioItem = ko.observable<PortofolioItemData>();

    countrySearchConfig = getCountrySearchConfig(this.country, { disableCreate: true });
    cropSearchConfig = getCropSearchConfig(this.crop, { disableCreate: true });
    seedTreatmentSearchConfig = getSeedTreatmentSearchConfig(this.seedTreatment, this.crop, { disableCreate: true });
    producerSearchConfig = getProducerSearchConfig(this.producer, { disableCreate: true });
    buyerSearchConfig = getBuyerSearchConfig(this.buyer, { disableCreate: true });
    piSearchConfig = getPISearchConfig(this.portofolioItem, {
        disableCreate: true
    });

    filters: ListFilter[];

    constructor() {
        this.filters = [
            { name: i18n.t('Country')(), slug: '', type: 'select', config: this.countrySearchConfig },
            { name: i18n.t('Crop')(), slug: '', type: 'select', config: this.cropSearchConfig },
            { name: i18n.t('Seed treatment')(), slug: '', type: 'select', config: this.seedTreatmentSearchConfig },
            { name: i18n.t('Seller')(), slug: '', type: 'select', config: this.producerSearchConfig },
            { name: i18n.t('Buyer')(), slug: '', type: 'select', config: this.buyerSearchConfig },
            { name: i18n.t('Year')(), slug: '', type: 'numeric', minValue: this.minYear, maxValue: this.maxYear },
            { name: i18n.t('PI')(), slug: '', type: 'select', config: this.piSearchConfig }
        ];
    }

    setInitial(filters: SeedTreatmentSalesFiltersData): Promise<void> {
        let country = filters.country ? countriesApi.retrieve(filters.country) : undefined;
        let crop = filters.crop ? cropsApi.retrieve(filters.crop) : undefined;
        let seedTreatment = filters.seed_treatment ? seedTreatmentApi.retrieve(filters.seed_treatment) : undefined;
        let producer = filters.producer ? producersApi.retrieve(filters.producer) : undefined;
        let buyer = filters.buyer ? buyersApi.retrieve(filters.buyer) : undefined;
        let portofolioItem = filters.portofolio_item ? portofolioItemApi.retrieve(filters.portofolio_item) : undefined;

        return Promise.all([country, crop, seedTreatment, producer, buyer, portofolioItem]).then(([country, crop, seedTreatment, producer, buyer, portofolioItem]) => {
            this.country(country);
            this.crop(crop);
            this.seedTreatment(seedTreatment);
            this.producer(producer);
            this.buyer(buyer);
            this.portofolioItem(portofolioItem);
            this.minYear(filters.min_year ? filters.min_year.toString() : null);
            this.maxYear(filters.max_year ? filters.max_year.toString() : null);
        });
    }

    toData(): SeedTreatmentSalesFiltersData {
        return {
            country: extractId(this.country),
            crop: extractId(this.crop),
            seed_treatment: extractId(this.seedTreatment),
            producer: extractId(this.producer),
            buyer: extractId(this.buyer),
            min_year: this.minYear() ? parseInt(this.minYear(), 10) : null,
            max_year: this.maxYear() ? parseInt(this.maxYear(), 10) : null,
            portofolio_item: extractId(this.portofolioItem),
        };
    }
}

class SeedTreatmentSalesScreen implements ListLoaderDelegate<SeedTreatmentSaleData, SeedTreatmentSale> {
    private salesFilters = new SeedTreatmentSalesFilters();
    filters = this.salesFilters.filters;
    loading = ko.observable(true);
    canCreate = session.isAtLeastEditor();

    listActions = addChangelogListAction('seed_treatment_sale');

    constructor(params: { filters: SeedTreatmentSalesFiltersData }) {
        this.salesFilters.setInitial(params.filters).then(() => {
            this.loading(false);
        });
    }

    fetch(params: ListRequestParams) {
        let data = this.salesFilters.toData();
        updateLocationWithQueryString(data);
        return seedTreatmentSaleApi.list({ ...params, ...data });
    }

    instantiate(data: SeedTreatmentSaleData) {
        return new SeedTreatmentSale(data);
    }

    getName(entity: SeedTreatmentSale) {
        return entity.country().name + ', ' + entity.seedTreatment().name + ', ' + entity.seller().name + ', ' + translate(entity.season().name_json) + '/' + entity.seasonYear();
    }

    getEditUrl(entity: SeedTreatmentSale) {
        return entity.editUrl;
    }

    remove(id: string) {
        return seedTreatmentSaleApi.remove(id);
    }

    canRemove(entity: SeedTreatmentSale) {
        return entity.canEdit();
    }

    getActions(entity: SeedTreatmentSale): Action[] {
        return addChangelogAction('seed_treatment_sale', entity.id());
    }
}

export let seedTreatmentSales = { name: 'seed-treatment-sales', viewModel: SeedTreatmentSalesScreen, template: template };

ko.components.register(seedTreatmentSales.name, seedTreatmentSales);
