import * as ko from 'knockout';

import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate } from 'main/components/list_loader';
import { LicenseData, licenseApi, PartnerLicenseData, CropData, cropsApi } from '../api/simple_api';
import { License } from '../models/license';
import { session } from 'main/session';
import { translate } from 'main/i18n_text';
import { addChangelogListAction, addChangelogAction } from 'main/screens/changelog';
import { Action } from 'main/components/basic_widgets';
import { BreederData, breedersApi, ProducerData, producersApi } from 'main/api/organizations';
import { FilterDelegate } from 'main/components/list_filters';
import i18n from 'main/i18n';
import { asArray, parsePortofolioItemsNames, updateLocationWithQueryString } from 'main/utils';
import { deflateList } from 'main/api/serialization';
import { countriesApi, CountryData } from 'main/api/countries';
import { portofolioItemApi, PortofolioItemData } from 'main/api/portofolio_items';

let template = require('raw-loader!../../templates/licenses.html').default;

class LicensesScreen implements ListLoaderDelegate<LicenseData | PartnerLicenseData, License> {
    loading = ko.observable(true);
    isStaff = !session.isCompany();
    canCreate = session.isAtLeastEditor();

    private producerFilter = ko.observableArray<ProducerData>();
    private breederFilter = ko.observableArray<BreederData>();
    private cropsFilter = ko.observableArray<CropData>();
    private countryFilter = ko.observableArray<CountryData>();
    private portofolioItemFilter = ko.observableArray<PortofolioItemData>();
    private fromYear = ko.observable('');
    private toYear = ko.observable('');
    newFilters: FilterDelegate[] = [
        { title: i18n.t('Producer')(), entities: this.producerFilter, list: producersApi.list },
        { title: i18n.t('Breeder')(), entities: this.breederFilter, list: breedersApi.list },
        { title: i18n.t('Crop')(), entities: this.cropsFilter, list: cropsApi.list },
        { title: i18n.t('Country')(), entities: this.countryFilter, list: countriesApi.list },
        {
            title: i18n.t('PI')(),
            entities: this.portofolioItemFilter,
            list: (params) =>
              new Promise((res, _) =>
                res(
                  portofolioItemApi.list(params).then((portofolioItems) => parsePortofolioItemsNames(portofolioItems))
                )
            ),
        },
        { title: i18n.t('From year')(), textValue: this.fromYear },
        { title: i18n.t('To year')(), textValue: this.toYear }
    ];

    listActions = addChangelogListAction('license');

    constructor(params: { filters: { producer_ids: string | string[], breeder_ids: string | string[], crop_ids: string | string[], country_ids: string | string[], portofolio_item_ids: string | string[], from_year: string, to_year: string } }) {
        this.fromYear(params.filters.from_year || '');
        this.toYear(params.filters.to_year || '');

        const producerIds = asArray(params.filters.producer_ids);
        const breederIds = asArray(params.filters.breeder_ids);
        const cropIds = asArray(params.filters.crop_ids);
        const countryIds = asArray(params.filters.country_ids);
        const portofolioItemIds = asArray(params.filters.portofolio_item_ids);
        const producerPromise = producerIds.length > 0 ? producersApi.list({ ids: producerIds }) : Promise.resolve<ProducerData[]>([]);
        const breederPromise = breederIds.length > 0 ? breedersApi.list({ ids: breederIds }) : Promise.resolve<BreederData[]>([]);
        const cropPromise = cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : Promise.resolve<CropData[]>([]);
        const countryPromise = countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);
        const portofolioItemsPromise = portofolioItemIds.length > 0 ? portofolioItemApi.list({ ids: portofolioItemIds }) : Promise.resolve<PortofolioItemData[]>([]);
        Promise.all([producerPromise, breederPromise, cropPromise, countryPromise, portofolioItemsPromise]).then(([producerData, breederData, cropData, countryData, portofolioItemsData]) => {
            if (producerData) {
                this.producerFilter(producerData);
            }
            if (breederData) {
                this.breederFilter(breederData);
            }

            if (cropData) {
                this.cropsFilter(cropData);
            }

            if (countryData) {
                this.countryFilter(countryData);
            }

            if (portofolioItemsData) {
                this.portofolioItemFilter(
                    parsePortofolioItemsNames(portofolioItemsData)
                );
            }

            this.loading(false);
        });
    }

    private getFilters() {
        const fromYear = parseInt(this.fromYear(), 10);
        const toYear = parseInt(this.toYear(), 10);

        return {
            producer_ids: deflateList(this.producerFilter),
            breeder_ids: deflateList(this.breederFilter),
            crop_ids: deflateList(this.cropsFilter),
            country_ids: deflateList(this.countryFilter),
            portofolio_item_ids: deflateList(this.portofolioItemFilter),
            from_year: isNaN(fromYear) ? null : fromYear,
            to_year: isNaN(toYear) ? null : toYear
        };
    }

    fetch(params: ListRequestParams) {
        const filters = this.getFilters();
        updateLocationWithQueryString(filters);
        return licenseApi.list({ ...params, ...filters });
    }

    instantiate(data: LicenseData) {
        return new License(data);
    }

    getName(entity: License) {
        let name = translate(entity.cropVariety().name_json) + ' ' + entity.startYear().toString();
        if (entity.endYear()) {
            return name + ' - ' + entity.endYear();
        }

        return name;
    }

    getEditUrl(entity: License) {
        return entity.editUrl;
    }

    remove(id: string) {
        return licenseApi.remove(id);
    }

    canRemove(entity: License) {
        return entity.canEdit();
    }

    getActions(entity: License): Action[] {
        return addChangelogAction('license', entity.id());
    }
}

export let licenses = { name: 'licenses', viewModel: LicensesScreen, template: template };

ko.components.register(licenses.name, licenses);
