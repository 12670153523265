import * as ko from 'knockout';

import i18n from 'main/i18n';
import { BaseForm } from 'main/screens/base_form';
import { createWithComponent } from 'main/utils/ko_utils';
import { NameI18nData } from 'main/api/names';
import { cropVarietyApi, licenseApi } from '../api/simple_api';
import { currentYear } from 'main/utils';
import { FormSelectSearchConfiguration } from 'main/components/form_select_search';
import { getUserSearchConfig, getBuyerSearchConfig } from '../models/helpers/search_configs';
import { TaskBulkCreateData, bulkCreateTasks, TaskProducerData, TaskUserType, TaskBuyerData } from '../api/tasks';
import { UserData } from 'main/api/users';
import { translate } from 'main/i18n_text';
import { CountryData } from 'main/api/countries';
import { SeasonData } from 'main/api/seasons';
import { getCountrySearchConfig, getSeasonSearchConfig, getProducerSearchConfig } from 'main/components/configs/search_configs';
import { ProducerData, BuyerData } from 'main/api/organizations';

let template = require('raw-loader!../../templates/task_edit.html').default;

class TaskEditScreen extends BaseForm<{}> {
    operationOptions = [
        { name: i18n.t('Production')(), value: 'production' },
        { name: i18n.t('Sale')(), value: 'sale' }
    ];
    targetOptions = [
        { name: i18n.t('Forecast')(), value: 'forecast' },
        { name: i18n.t('Actual data')(), value: 'actual_data' }
    ];

    user = ko.observable<UserData>(null);
    country = ko.observable<CountryData>(null).extend({ required: true });
    season = ko.observable<SeasonData>(null).extend({
        required: true,
        serverError: true
    });
    seasonYear = ko.observable<string>(currentYear()).extend({
        digit: true,
        required: true
    });

    operation = ko.observable<'production' | 'sale'>('production');
    target = ko.observable<'forecast' | 'actual_data'>('forecast');
    producers = ko.observableArray<TaskProducer>();
    buyers = ko.observableArray<TaskBuyer>();

    userSearchConfig = getUserSearchConfig(this.user);
    countrySearchConfig = getCountrySearchConfig(this.country);
    seasonSearchConfig = getSeasonSearchConfig(this.season, this.country, { disableCreate: true });

    forUserType: TaskUserType;
    globalErrors = ko.observableArray();

    constructor(params: { forUserType: TaskUserType }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super({});

        this.forUserType = params.forUserType;
        if (params.forUserType === 'staff') {
            this.user = this.user.extend({ required: true });
        }

        if (params.forUserType === 'buyer') {
            this.operation('sale');
            this.operationOptions.splice(0, 1);
            this.buyers.push(new TaskBuyer(this.country));
        } else {
            this.producers.push(new TaskProducer(this.country));
        }

        this.focusFirst(componentInfo.element);
    }

    private toData(): TaskBulkCreateData {
        return {
            user: this.user(),
            country: this.country(),
            season_year: parseInt(this.seasonYear(), 10),
            season: this.season(),
            operation: this.operation(),
            target: this.target(),
            producers: this.producers().map(prod => prod.toData()),
            buyers: this.buyers().map(buyer => buyer.toData())
        };
    }

    addBuyer = () => {
        this.buyers.push(new TaskBuyer(this.country));
    }

    addProducer = () => {
        this.producers.push(new TaskProducer(this.country));
    }

    removeBuyer = (item: TaskBuyer) => {
        this.buyers.remove(item);
    }

    removeProducer = (prod: TaskProducer) => {
        this.producers.remove(prod);
    }

    addAllLicensed = (prod: TaskProducer) => {
        let year = parseInt(this.seasonYear(), 10);
        if (isNaN(year)) {
            return;
        }
        if (!prod.producer()) {
            return;
        }

        prod.loadingLicensed(true);

        licenseApi.licensedVarieties({ season_year: year, producer: prod.producer() }).then(varieties => {
            prod.cropVarieties(varieties);
            prod.loadingLicensed(false);
        }).catch(() => {
            prod.loadingLicensed(false);
        });
    }

    save = () => {
        this.globalErrors([]);

        if (this.validateLocal(this)) {
            let data = this.toData();
            this.executeSaveRequest(bulkCreateTasks(data)).then((validation) => {
                this.onRemoteValidation(data, this, validation);

                if (!validation.isValid && validation.errors['non_field_errors']) {
                    this.globalErrors(validation.errors['non_field_errors']);
                }
            });
        }
    }
}

class TaskItem {
    loadingLicensed = ko.observable(false);

    constructor(protected country: KnockoutObservable<CountryData>) {
    }

    cropVarieties = ko.observableArray<NameI18nData>().extend({
        required: true
    });

    cropVarietySearchConfig: FormSelectSearchConfiguration<NameI18nData> = {
        getSummaryName: (entity) => {
            return translate(entity.name_json);
        },
        list: (params) => {
            return cropVarietyApi.list(params);
        },
        entities: this.cropVarieties
    };
}

class TaskProducer extends TaskItem {
    producer = ko.observable<ProducerData>(null).extend({
        required: true
    });

    producerSearchConfig = getProducerSearchConfig(this.producer, { country: this.country });

    toData(): TaskProducerData {
        return {
            producer: this.producer(),
            crop_varieties: this.cropVarieties()
        };
    }
}

class TaskBuyer extends TaskItem {
    buyer = ko.observable<BuyerData>(null).extend({
        required: true
    });

    buyerSearchConfig = getBuyerSearchConfig(this.buyer, { country: this.country });

    toData(): TaskBuyerData {
        return {
            buyer: this.buyer(),
            crop_varieties: this.cropVarieties()
        };
    }
}

export let taskEdit = { name: 'task-edit', viewModel: createWithComponent(TaskEditScreen), template: template };

ko.components.register(taskEdit.name, taskEdit);
