import * as ko from 'knockout';

import i18n from 'main/i18n';
import { AssumptionData, BuyingFrequencyData } from '../api/simple_api';
import { readDecimal, emptyToNull, MONTH_OPTIONS } from 'main/utils';
import { NameI18nData } from 'main/api/names';
import { session } from 'main/session';
import { getCropSearchConfig, getCropVarietySearchConfig } from './helpers/search_configs';
import { CountryData } from 'main/api/countries';
import { getCountrySearchConfig, getCropVarietyTypeSearchConfig } from 'main/components/configs/search_configs';
import { translate } from 'main/i18n_text';
import { CropVarietyData } from 'main/api/crop_varieties';

export class Assumption {
    months = MONTH_OPTIONS;

    id = ko.observable<string>(null);
    crop = ko.observable<NameI18nData>(null).extend({
        required: true
    });
    country = ko.observable<CountryData>(null).extend({
        required: true
    });
    year = ko.observable<string>('').extend({
        required: true,
        digit: true,
        serverError: true
    });
    month = ko.observable(1);
    avgPlotSize = ko.observable('').extend({
        number: true,
        serverError: true,
        required: true
    });
    percentageOfMaleFarmers = ko.observable('').extend({
        number: true,
        serverError: true,
        required: true,
    });
    percentageOfFemaleFarmers = ko.observable('').extend({
        number: true,
        serverError: true,
        required: true,
        validation: {
            validator: (value) => {
                const fPercentage = parseFloat(value);
                const mPercentage = parseFloat(this.percentageOfMaleFarmers());
                return Math.abs(fPercentage + mPercentage - 100) < 0.00001;
            },
            message: i18n.t('Percentage of male and female farmers must add up to 100')(),
        }
    });
    annualCropAreaGrown = ko.observable('').extend({
        number: true,
        serverError: true
    });
    seedPlantingDensity = ko.observable('').extend({
        number: true,
        serverError: true,
        required: true
    });
    seedProduction = ko.observable('').extend({
        number: true,
        serverError: true
    });
    seedImports = ko.observable('').extend({
        number: true,
        serverError: true
    });
    seedPrice = ko.observable('').extend({
        number: true,
        serverError: true
    });
    earlyGenSeedPrice = ko.observable('').extend({
        number: true,
        serverError: true
    });
    cropPrice = ko.observable('').extend({
        number: true,
        serverError: true
    });
    grossProfit = ko.observable('').extend({
        number: true,
        serverError: true
    });
    baselineYield = ko.observable('').extend({
        number: true,
        serverError: true
    });
    yieldGain = ko.observable('').extend({
        number: true,
        serverError: true
    });
    checkVariety = ko.observable<CropVarietyData>(null).extend({
        serverError: true
    });
    comment = ko.observable('');
    complete = ko.observable(false);
    buyingFrequencies = ko.observableArray<BuyingFrequency>();
    canEdit = ko.pureComputed(() => {
        return !this.readOnly && (!this.id() || !this.country() || session.isAtLeastHeadFor(this.country()));
    });
    preserveHistory = ko.observable(true);

    editUrl: string;

    cropSearchConfig = getCropSearchConfig(this.crop);

    countrySearchConfig = getCountrySearchConfig(this.country, { disableCreate: false, role: 'head' });
    checkVarietySearchConfig = getCropVarietySearchConfig(this.checkVariety, this.crop);

    existingYear: number = null;
    existingMonth: number = null;

    constructor(private readOnly: boolean, public data?: AssumptionData) {
        if (data) {
            this.id(data.id);
            this.crop(data.crop);
            this.country(data.country);
            this.avgPlotSize(readDecimal(data.avg_plot_size));
            this.percentageOfMaleFarmers(readDecimal(data.percentage_of_male_farmers));
            this.percentageOfFemaleFarmers(readDecimal(data.percentage_of_female_farmers));
            this.annualCropAreaGrown(readDecimal(data.annual_crop_area_grown));
            this.seedPlantingDensity(readDecimal(data.seed_planting_density));
            this.seedProduction(readDecimal(data.seed_production));
            this.seedImports(readDecimal(data.seed_imports));
            this.seedPrice(readDecimal(data.seed_price));
            this.earlyGenSeedPrice(readDecimal(data.early_gen_seed_price));
            this.cropPrice(readDecimal(data.crop_price));
            this.grossProfit(readDecimal(data.gross_profit));
            this.baselineYield(readDecimal(data.baseline_yield));
            this.yieldGain(readDecimal(data.yield_gain));
            this.checkVariety(data.check_variety);
            this.comment(data.comment);
            this.complete(data.complete);

            if (data.buying_frequencies) {
                this.buyingFrequencies(data.buying_frequencies.map(bfData => new BuyingFrequency(this.preserveHistory, bfData)));
            }

            this.existingYear = data.year;
            this.existingMonth = data.month;
        }

        this.editUrl = this.readOnly ? '/assumption_histories/' + this.id() + '/' : '/assumptions/' + this.id() + '/';
    }

    hasSubServerError = ko.pureComputed(() => {
        for (let item of this.buyingFrequencies()) {
            if (item.hasServerError()) {
                return true;
            }
        }
    });

    addBuyingFrequency = () => {
        this.buyingFrequencies.push(new BuyingFrequency(this.preserveHistory));
    }

    removeBuyingFrequency = (bf: BuyingFrequency) => {
        this.buyingFrequencies.remove(bf);
    }

    toData(): AssumptionData {
        return {
            id: this.preserveHistory() ? null :  this.id(),
            crop: this.crop(),
            country: this.country(),
            year: parseInt(this.year(), 10),
            month: this.month(),
            avg_plot_size: emptyToNull(this.avgPlotSize()),
            percentage_of_male_farmers: emptyToNull(this.percentageOfMaleFarmers()),
            percentage_of_female_farmers: emptyToNull(this.percentageOfFemaleFarmers()),
            annual_crop_area_grown: emptyToNull(this.annualCropAreaGrown()),
            seed_planting_density: emptyToNull(this.seedPlantingDensity()),
            seed_production: emptyToNull(this.seedProduction()),
            seed_imports: emptyToNull(this.seedImports()),
            seed_price: emptyToNull(this.seedPrice()),
            early_gen_seed_price: emptyToNull(this.earlyGenSeedPrice()),
            crop_price: emptyToNull(this.cropPrice()),
            gross_profit: emptyToNull(this.grossProfit()),
            baseline_yield: emptyToNull(this.baselineYield()),
            yield_gain: this.yieldGain(),
            check_variety: this.checkVariety(),
            comment: this.comment(),
            buying_frequencies: this.buyingFrequencies().map(bf => bf.toData())
        };
    }
}

class BuyingFrequency {
    private id: string;
    cropVarietyType = ko.observable<NameI18nData>(null).extend({
        required: true,
        serverError: true
    });
    frequency = ko.observable('').extend({
        digit: true,
        required: true,
        serverError: true
    });

    cropVarietyTypeSearchConfig = getCropVarietyTypeSearchConfig(this.cropVarietyType);

    constructor(private preserveHistory: KnockoutObservable<boolean>, data?: BuyingFrequencyData) {
        if (data) {
            this.id = data.id;
            this.cropVarietyType(data.crop_variety_type);
            this.frequency(data.frequency.toString());
        }
    }

    hasServerError() {
        return !!(this.cropVarietyType.serverError() || this.frequency.serverError());
    }

    summary = ko.pureComputed(() => {
        if (!this.cropVarietyType() || isNaN(parseInt(this.frequency(), 10))) {
            return '';
        }

        return i18n.t('{{ cropVarietyType }} every {{ frequency }} years', {
            cropVarietyType: translate(this.cropVarietyType().name_json),
            frequency: this.frequency()
        })();
    });

    toData(): BuyingFrequencyData {
        return {
            id: this.preserveHistory() ? null :  this.id,
            crop_variety_type: this.cropVarietyType(),
            frequency: parseInt(this.frequency(), 10)
        };
    }
}
