import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ProductionData, TaskData, productionApi } from '../api/simple_api';
import { readDecimal, currentYear, emptyToNull } from 'main/utils';
import { session } from 'main/session';
import { CountryData } from 'main/api/countries';
import { SeasonData } from 'main/api/seasons';
import { getCountrySearchConfig, getSeasonSearchConfig, getProducerSearchConfig, getProjectSearchConfig, getPISearchConfig } from 'main/components/configs/search_configs';
import { ProducerData } from 'main/api/organizations';
import { parseDateTime } from 'main/api/serialization';
import { ProjectData } from 'main/api/projects';
import { PortofolioItemData } from 'main/api/portofolio_items';
import { PartnerCropVarietyData } from 'main/api/crop_varieties';
import { ProductionSale } from './sale';
import { makeHasError } from 'main/utils/ko_utils';
import { removeDialog } from 'main/components/remove_dialog';

export class Production {
    id = ko.observable<string>(null);
    country = ko.observable<CountryData>(null).extend({
        required: true
    });
    project = ko.observable<ProjectData>(null).extend({
        required: false
    });
    cropVariety = ko.observable<PartnerCropVarietyData>(null).extend({
        required: true
    });
    producer = ko.observable<ProducerData>(null).extend({
        required: true
    });
    portofolioItem = ko.observable<PortofolioItemData>(null).extend({
        required: true
    });
    seasonYear = ko.observable<string>(currentYear()).extend({
        required: true,
        digit: true,
        serverError: true
    });
    season = ko.observable<SeasonData>(null).extend({ required: true, serverError: true });
    quantity = ko.observable('').extend({
        number: true,
        serverError: true
    });
    quantityForecast = ko.observable('').extend({
        number: true,
        serverError: true
    });
    quantityEarlyGen = ko.observable('').extend({
        number: true,
        serverError: true
    });
    quantityEarlyGenForecast = ko.observable('').extend({
        number: true,
        serverError: true
    });
    areaGrownCertifiedSeeds = ko.observable('').extend({
        number: true,
        serverError: true
    });
    areaGrownEGS = ko.observable('').extend({
        number: true,
        serverError: true
    });
    areaGrownCertifiedSeedsForecast = ko.observable('').extend({
        number: true,
        serverError: true
    });
    areaGrownEGSForecast = ko.observable('').extend({
        number: true,
        serverError: true
    });
    comment = ko.observable('');
    private alreadyValidated = ko.observable(false);
    validated = ko.observable(false);
    validatedBy: string = null;
    validatedAt: Date = null;
    totalSales: number = null;

    sales = ko.observableArray<ProductionSale>();

    canEdit = ko.pureComputed(() => {
        return !this.id()
            || !this.country()
            || !this.producer()
            // NOTE: in case of companies, user can only see editable productions to begin with
            || session.isCompany()
            || session.isPartner()
            || session.isAtLeastEditorFor(this.country());
    });
    canValidate = ko.pureComputed(() => {
        return !this.alreadyValidated() && !session.isCompany() && this.canEdit();
    });

    showEarlyGen = ko.observable(false);
    showEarlyGenForecast = ko.observable(false);

    editUrl: string;

    countrySearchConfig = getCountrySearchConfig(this.country);
    seasonSearchConfig = getSeasonSearchConfig(this.season, this.country, { disableCreate: true });
    projectSearchConfig = getProjectSearchConfig(this.project, {
        disableCreate: true,
        tenantId: session.tenant().slug,
    });
    piSearchConfig = getPISearchConfig(this.portofolioItem, {
        disableCreate: true,
        tenantId: session.tenant().slug,
    });
    producerSearchConfig = getProducerSearchConfig(this.producer);

    isCompany = session.isCompany();
    forceForecastOnly = false;
    actualOnly = false;
    taskTitle = '';

    forecastOnly = ko.pureComputed(() => {
        let year = parseInt(this.seasonYear(), 10);
        return this.forceForecastOnly || (year && !isNaN(year) && year > new Date().getFullYear());
    });

    selectedSale = ko.observable<ProductionSale | null>(null);

    hasError = makeHasError(
        this.country, this.cropVariety, this.producer, this.seasonYear, this.season,
        this.quantity, this.quantityForecast, this.quantityEarlyGen, this.quantityEarlyGenForecast,
        this.areaGrownCertifiedSeeds, this.areaGrownCertifiedSeedsForecast, this.areaGrownEGSForecast,
        this.areaGrownEGS, this.comment,
    );

    canSelectProducer = ko.pureComputed(() => !this.taskData?.user && !(session.isCompany() && session.tenant().producers.length === 1));

    constructor(public data?: ProductionData, public taskData?: TaskData, type?: 'actual' | 'forecast' | 'both') {
        if (taskData) {
            this.forceForecastOnly = taskData.target === 'forecast';
            this.actualOnly = taskData.target === 'actual_data';

            if (this.forecastOnly()) {
                this.taskTitle = i18n.t('Production forecast')();
                this.quantityForecast = this.quantityForecast.extend({ required: true });
                this.areaGrownCertifiedSeedsForecast = this.areaGrownCertifiedSeedsForecast.extend({ required: true });
                this.areaGrownEGSForecast = this.areaGrownEGSForecast.extend({ required: true });
            }
            if (this.actualOnly) {
                this.taskTitle = i18n.t('Actual Production')();
                this.quantity = this.quantity.extend({ required: true });
                this.areaGrownCertifiedSeeds = this.areaGrownCertifiedSeeds.extend({ required: true });
                this.areaGrownEGS = this.areaGrownEGS.extend({ required: true });
            }
        } else {
            this.actualOnly = type === 'actual';
            this.forceForecastOnly = type === 'forecast';

            if (!this.canSelectProducer()) {
                let producer = session.tenant().producers[0];
                // country/producer are not shown, so we only need the id
                this.country({ id: producer.country_id, name: '', iso_country_code: '', regions: [], season_1: null, season_2: null, season_3: null, month_of_harvest_1: null, month_of_harvest_2: null, month_of_harvest_3: null, user_roles: [] });
                this.producer({
                    id: producer.id,
                    name: '',
                    country: null,
                    contact: null,
                    address: null,
                    phone: null,
                    email: null,
                    responsible: null,
                    organization_type: null,
                    users_emails: null
                });
            }
        }

        if (data) {
            this.id(data.id);
            this.country(data.country);
            this.cropVariety(data.crop_variety);
            this.producer(data.producer);
            this.seasonYear(data.season_year.toString());
            this.season(data.season);
            this.project(data.project);
            this.portofolioItem(data.portofolio_item);
            this.quantity(readDecimal(data.quantity));
            this.quantityForecast(readDecimal(data.quantity_forecast));
            this.quantityEarlyGen(readDecimal(data.quantity_early_gen));
            this.quantityEarlyGenForecast(readDecimal(data.quantity_early_gen_forecast));
            this.areaGrownCertifiedSeeds(readDecimal(data.area_grown_certified_seeds));
            this.areaGrownEGS(readDecimal(data.area_grown_egs));
            this.areaGrownCertifiedSeedsForecast(readDecimal(data.area_grown_certified_seeds_forecast));
            this.areaGrownEGSForecast(readDecimal(data.area_grown_egs_forecast));
            this.comment(data.comment);

            if (this.quantityEarlyGen() !== '') {
                this.showEarlyGen(true);
            }
            if (this.quantityEarlyGenForecast() !== '') {
                this.showEarlyGenForecast(true);
            }

            let sales = (data.sales || []).slice();
            sales.sort((s1, s2) => (s1.order_in_production || 0) - (s2.order_in_production || 0));
            this.sales(sales.map(saleData => new ProductionSale(this, saleData)));

            if (data.validated_by) {
                this.alreadyValidated(true);
                this.validated(true);
                this.validatedBy = data.validated_by;
                this.validatedAt = parseDateTime(data.validated_at);
            }

            this.totalSales = data.total_sales;
        }

        if (taskData) {
            this.country(taskData.producer.country);
            this.cropVariety(taskData.crop_variety);
            this.producer(taskData.producer);
            this.seasonYear(taskData.season_year.toString());
            this.season(taskData.season);
        }

        this.editUrl = '/productions/' + this.id();
    }

    toData(): ProductionData {
        return {
            id: this.id(),
            country: this.country(),
            crop_variety: this.cropVariety(),
            producer: this.producer(),
            season_year: parseInt(this.seasonYear(), 10),
            season: this.season(),
            quantity: emptyToNull(this.quantity()),
            quantity_forecast: emptyToNull(this.quantityForecast()),
            quantity_early_gen: emptyToNull(this.quantityEarlyGen()),
            quantity_early_gen_forecast: emptyToNull(this.quantityEarlyGenForecast()),
            area_grown_certified_seeds: emptyToNull(this.areaGrownCertifiedSeeds()),
            area_grown_egs: emptyToNull(this.areaGrownEGS()),
            area_grown_certified_seeds_forecast: emptyToNull(this.areaGrownCertifiedSeedsForecast()),
            area_grown_egs_forecast: emptyToNull(this.areaGrownEGSForecast()),
            comment: this.comment(),
            task_id: this.taskData ? this.taskData.id : null,
            validated: this.validated(),
            sales: this.sales().map((sale, idx) => sale.toData(idx)),
            project: this.project(),
            portofolio_item: this.portofolioItem(),
        };
    }

    saveRequest() {
        return productionApi.save(this.toData());
    }

    toggleShowEarlyGen = () => {
        this.showEarlyGen(true);
    }

    toggleShowEarlyGenForecast = () => {
        this.showEarlyGenForecast(true);
    }

    removeSale = (sale: ProductionSale) => {
        removeDialog(
            i18n.t('sale')(),
            [i18n.t('Are you sure you want to delete this sale?')()],
            () => Promise.resolve({ did_delete: true, dependant_records: [] })
        ).then(() => {
            let idx = this.sales().indexOf(sale);
            if (idx >= 0) {
                this.sales.splice(idx, 1);
            }
            if (idx > 0) {
                this.selectedSale(this.sales()[Math.max(0, idx - 1)]);
            } else {
                this.selectedSale(null);
            }
        });
    };

    addSale = () => {
        let sale = new ProductionSale(this);
        this.sales.push(sale);
        this.selectedSale(sale);
    };

    selectProduction = () => {
        this.selectedSale(null);
    };

    selectSale = (sale: ProductionSale) => {
        this.selectedSale(sale);
    }

    isProductionSelected = () => this.selectedSale() === null;
    isSaleSelected = (sale: ProductionSale) => this.selectedSale() === sale;
}
