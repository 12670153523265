import * as ko from 'knockout';
import { PortofolioItemData, portofolioItemApi } from '../api/portofolio_items';
import { PortofolioItem } from '../models/portofolio_item';
import { createWithComponent } from '../utils/ko_utils';
import { BaseEditScreen, BaseEditParams } from './base_edit';

let template = require('raw-loader!../../templates/portofolio_item_edit.html').default;

class PortofolioItemEditScreen extends BaseEditScreen<PortofolioItemData, PortofolioItem> {
    constructor(params: BaseEditParams<PortofolioItemData>, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super({ api: portofolioItemApi, focus: true}, params, componentInfo);
        this.globalError.subscribe(() => {
            if(this.globalError()) {
                this.entity().number.setError(this.globalError());
            }
        });
    }

    instantiate(data: PortofolioItemData) {
        return new PortofolioItem(data);
    }
}

export let portofolioItemEdit = {
    name: 'portofolio_item-edit',
    viewModel: createWithComponent(PortofolioItemEditScreen),
    template: template,
};

ko.components.register(portofolioItemEdit.name, portofolioItemEdit);