import * as ko from 'knockout';
import page from 'page';

import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate } from 'main/components/list_loader';
import { User, s2bimUserOptions } from 'main/models/user';
import * as usersApi from 'main/api/users';
import { Action } from 'main/components/basic_widgets';
import { session } from 'main/session';
import i18n from 'main/i18n';
import { addChangelogListAction, addChangelogAction } from 'main/screens/changelog';
import { canViewChangelog } from 'main/permissions';

let template = require('raw-loader!../../templates/users.html').default;

class UsersScreen implements ListLoaderDelegate<usersApi.UserData, User> {
    listActions = addChangelogListAction('user');

    fetch(params: ListRequestParams) {
        return usersApi.list(params);
    }

    instantiate(userData: usersApi.UserData) {
        return new User(s2bimUserOptions, userData);
    }

    getEditUrl(entity: User) {
        return entity.editUrl();
    }

    remove(id: string) {
        return usersApi.remove(id);
    }

    canRemove(user: User) {
        return true;
    }

    getActions(entity: User): Action[] {
        let actions: Action[] = [];
        if (canViewChangelog(null)) {
            actions.push({
                icon: 'change_history',
                title: i18n.t('User log')(),
                cssClass: '',
                onClick: () => page(session.toTenantPath('/changelog/?userId=' + entity.id() + '&backRef=' + encodeURIComponent(location.pathname + location.search)))
            });
        }

        return addChangelogAction('user', entity.id(), actions);
    }
}

export let users = { name: 'users', viewModel: UsersScreen, template: template };

ko.components.register(users.name, users);
