/// <reference path="../../static/ts/type_definitions/globals.d.ts"/>

import page from 'page';

import i18n from 'main/i18n';
import { app } from 'main/app';
import { session } from 'main/session';
import { requireAuthorized, setLoginParams } from 'main/screens/login';
import { users } from './screens/users';
import { userEdit } from './screens/user_edit';
import { countries } from 'main/screens/countries';
import { countryEdit } from 'main/screens/country_edit';
import { buyers, producers } from 'main/screens/organizations';
import { buyerEdit, producerEdit } from 'main/screens/organization_edit';
import { licenses } from './screens/licenses';
import { licenseEdit } from './screens/license_edit';
import { assumptions } from './screens/assumptions';
import { assumptionEdit } from './screens/assumption_edit';
import { productions } from './screens/productions';
import { productionEdit } from './screens/production_edit';
import { sales } from './screens/sales';
import { saleEdit } from './screens/sale_edit';
import { organizationalRegions } from 'main/screens/organizational_regions';
import { orgRegionEdit } from 'main/screens/organizational_region_edit';
import { tasks } from './screens/tasks';
import { taskEdit } from './screens/task_edit';
import { settings } from './screens/settings';
import { notFound } from 'main/screens/not_found';
import { myTasks } from './screens/my_tasks';
import { organizationTypeEdit } from 'main/screens/organization_type_edit';
import { organizationTypes } from 'main/screens/organization_types';
import { tasksEnter } from './screens/tasks_enter';
import { dashboard } from './screens/dashboard';
import { dashboard_v2 } from './screens/dashboard_v2';
import { projects } from 'main/screens/projects';
import { projectEdit } from 'main/screens/project_edit';
import { portofolioItemEdit } from 'main/screens/portofolio_item_edit';
import { portofolioItems } from 'main/screens/portofolio_items';
import { seedTreatments } from './screens/seed_treatments';
import { seedTreatmentEdit } from './screens/seed_treatment_edit';
import { seedTreatmentSales } from './screens/seed_treatment_sales';
import { seedTreatmentSaleEdit } from './screens/seed_treatment_sale_edit';
import { setupBaseRoutes, getFiltersParams, setupCreateUpdateRoutes, parseCanonicalPath } from 'main/utils/routes';
import { crops } from 'main/screens/crops';
import { importDimensions } from 'main/screens/import_dimensions';
import { cropVarieties } from 'main/screens/crop_varieties';
import { registrations } from 'main/screens/registrations';
import { registrationHistories } from 'main/screens/registration_histories';
import { seasons } from 'main/screens/seasons';
import { seasonEdit } from 'main/screens/season_edit';
import { changelogScreen } from 'main/screens/changelog';

export function setupRoutes() {
    setupBaseRoutes();

    setLoginParams({
        motto: i18n.t('Seeds 2 Business Impact Model')(),
        showFooter: false
    });

    page('*', (ctx, next) => {
        app.showNavigationBar(true); // default
        next();
    });

    page('/', requireAuthorized, (ctx, next) => {
        page.redirect(session.toTenantPath(defaultPage()));
    });

    page('/t/:tenantId/', requireAuthorized, (ctx, next) => {
        page.redirect(session.toTenantPath(defaultPage()));
    });

    page('/t/:tenantId/my_tasks/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: myTasks.name });
    });

    page('/t/:tenantId/tasks_enter/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: tasksEnter.name, params: {} });
    });

    page('/t/:tenantId/productions/new/:taskId/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: productionEdit.name, params: { taskId: ctx.params.taskId } });
    });

    page('/t/:tenantId/productions/new_forecast/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: productionEdit.name, params: { type: 'forecast' } });
    });

    page('/t/:tenantId/productions/new_actual/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: productionEdit.name, params: { type: 'actual' } });
    });

    page('/t/:tenantId/productions/:id/:taskId/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: productionEdit.name, params: { id: ctx.params.id, taskId: ctx.params.taskId } });
    });

    page('/t/:tenantId/sales/new/:taskId/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: saleEdit.name, params: { taskId: ctx.params.taskId } });
    });

    page('/t/:tenantId/sales/new_forecast/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: saleEdit.name, params: { type: 'forecast' } });
    });

    page('/t/:tenantId/sales/new_actual/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: saleEdit.name, params: { type: 'actual' } });
    });

    page('/t/:tenantId/sales/:id/:taskId/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: saleEdit.name, params: { id: ctx.params.id, taskId: ctx.params.taskId } });
    });

    page('/t/:tenantId/assumption_histories/:id/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: assumptionEdit.name, params: { id: ctx.params.id, readOnly: true } });
    });

    page('/t/:tenantId/assumption_histories/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: assumptions.name, params: { history: true, ...getFiltersParams(ctx) } });
    });

    // NOTE: title is not used in s2bim, just pass the empty string
    setupCreateUpdateRoutes([
        { baseName: 'users', title: '', listComponent: users, editComponent: userEdit },
        { baseName: 'countries', title: '', listComponent: countries, editComponent: countryEdit },
        { baseName: 'buyers', title: '', listComponent: buyers, editComponent: buyerEdit },
        { baseName: 'producers', title: '', listComponent: producers, editComponent: producerEdit },
        { baseName: 'licenses', title: '', listComponent: licenses, editComponent: licenseEdit },
        { baseName: 'assumptions', title: '', listComponent: assumptions, editComponent: assumptionEdit },
        { baseName: 'productions', title: '', listComponent: productions, editComponent: productionEdit },
        { baseName: 'sales', title: '', listComponent: sales, editComponent: saleEdit },
        { baseName: 'regions', title: '', listComponent: organizationalRegions, editComponent: orgRegionEdit },
        { baseName: 'tasks', title: '', listComponent: tasks, editComponent: taskEdit, params: { forUserType: 'producer' } },
        { baseName: 'user_tasks', title: '', listComponent: tasks, editComponent: taskEdit, params: { forUserType: 'staff' } },
        { baseName: 'buyer_tasks', title: '', listComponent: tasks, editComponent: taskEdit, params: { forUserType: 'buyer' } },
        { baseName: 'organization_types', title: '', listComponent: organizationTypes, editComponent: organizationTypeEdit },
        { baseName: 'projects', title: '', listComponent: projects, editComponent: projectEdit },
        { baseName: 'portofolio_items', title: '', listComponent: portofolioItems, editComponent: portofolioItemEdit },
        { baseName: 'seed_treatments', title: '', listComponent: seedTreatments, editComponent: seedTreatmentEdit },
        { baseName: 'seed_treatment_sales', title: '', listComponent: seedTreatmentSales, editComponent: seedTreatmentSaleEdit },
        { baseName: 'crops', title: '', listComponent: crops },
        { baseName: 'crop_varieties', title: '', listComponent: cropVarieties },
        { baseName: 'registrations', title: '', listComponent: registrations },
        { baseName: 'registration_histories', title: '', listComponent: registrationHistories },
        { baseName: 'seasons', title: '', listComponent: seasons, editComponent: seasonEdit }
    ]);

    page('/t/:tenantId/settings/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: settings.name });
    });

    page('/t/:tenantId/dashboards_v1/internal/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: dashboard.name, params: { ...getFiltersParams(ctx), public: false } });
    });

    page('/t/:tenantId/dashboards/internal/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: dashboard_v2.name, params: { ...getFiltersParams(ctx), public: false } });
    });

    page('/t/:tenantId/dashboards/public/', (ctx, next) => {
        app.showNavigationBar(false);
        app.currentPage({ name: dashboard_v2.name, params: { ...getFiltersParams(ctx), tenantId: ctx.params.tenantId, public: true } });
    });

    page('/t/:tenantId/dimensions/crop/import/', requireAuthorized, (ctx, next) => {
        app.currentPage({ name: importDimensions.name, params: { dmIdOrSlug: 'crop' } });
    });

    page('/t/:tenantId/changelog/', requireAuthorized, ctx => {
        // NOTE: querystring is already decoded, which is wrong since we have url-encoded parameters in the query string.
        // so we use the canonical path, which is still encoded.
        app.currentPage({ name: changelogScreen.name, params: parseCanonicalPath(ctx.canonicalPath) });
    });

    page('*', () => {
        app.currentPage({ name: notFound.name });
    });
}

function defaultPage() {
    if (session.isPartner()) {
        return '/sales/';
    } else if (session.isCompany()) {
        return '/my_tasks/';
    } else {
        return '/dashboards/internal/';
    }
}

