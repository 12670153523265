import * as ko from 'knockout';

import { BaseForm } from 'main/screens/base_form';
import { Sale } from '../models/sale';
import { SaleData, saleApi, tasksApi } from '../api/simple_api';
import { Deferred } from 'main/utils/deferred';
import { createWithComponent } from 'main/utils/ko_utils';
import { setGlobalError } from 'main/screens/base_edit';

let template = require('raw-loader!../../templates/sale_edit.html').default;

class SaleEditScreen extends BaseForm<SaleData> {
    entity = ko.observable<Sale>(null);
    globalError = ko.observable('');

    constructor(params: { id: string, taskId?: string, result?: Deferred<SaleData>, type?: 'actual' | 'forecast' | 'both' }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super(params);

        let request = params.id ? saleApi.retrieve(params.id) : undefined;
        let taskReq = params.taskId ? tasksApi.retrieve(params.taskId) : undefined;
        let promise = Promise.all([request, taskReq]).then(([data, taskData]) => {
            this.entity(new Sale(data, taskData, params.type));
        });
        this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    }

    dispose() {
        this.entity().dispose();
    }

    save = () => {
        if (! this.entity()) {
            return;
        }

        if (this.validateLocal(this.entity)) {
            let data = this.entity().toData();
            this.executeSaveRequest(saleApi.save(data)).then((validation) => {
                this.onRemoteValidation(data, this.entity(), validation);
                setGlobalError(this.globalError, validation);
            });
        }
    }
}

export let saleEdit = { name: 'sale-edit', viewModel: createWithComponent(SaleEditScreen), template: template };

ko.components.register(saleEdit.name, saleEdit);
