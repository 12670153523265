import { request, requestRaw } from 'main/api/request';

export interface DashboardV2Params {
  project: string;
  crop_category: string;
  crops: string[];
  crop_variety: string;
  countries: string[];
  region: string;
  min_date: string;
  max_date: string;
  portofolio_items: string[];
  display_only_validated_data: boolean;
}

export interface DashboardOverviewData {
  seed_productions: {
    total_produced: number;
    total_forecasted: number;
    egs_produced: number;
    egs_forecasted: number;
    certified_produced: number;
    certified_forecasted: number;
  };
  seed_sales: {
    total_price: number;
    total_quantity: number;
    total_price_cs: number;
    total_quantity_cs: number;
    total_price_egs: number;
    total_quantity_egs: number;
  };
  diffusion_of_new_varieties: {
    area_under_seed_production: {
      total_area_certified: number;
      total_area_certified_forecast: number;
      total_area_egs: number;
      total_area_egs_forecast: number;
    };
    area_extrapolated_from_sales: {
      total_extrapolated_certified: number;
      total_extrapolated_certified_forecast: number;
      total_extrapolated_egs: number;
      total_extrapolated_egs_forecast: number;
    };
  };
  diffusion_of_new_varieties_suite: {
    male_farmers: number;
    female_farmers: number;
  };
  variety_registration_and_licensing: {
    regional: number;
    national: number;
    licenses_count_by_year: { year: number; count: number }[];
  };
}

export interface DashboardTableData {
  title: string;
  headers: string[];
  rows: (string | number)[][];
  total: string;
}

export type DashboardTableSource =
  | 'productions'
  | 'sales'
  | 'diffusion_and_impact'
  | 'registration_and_licensing'
  | 'diffusion_and_impact_suite';

export enum DashboardTableSourceEnum {
  PRODUCTIONS = 'productions',
  SALES = 'sales',
  DIFFUSION_AND_IMPACT = 'diffusion_and_impact',
  REGISTRATION_AND_LICENSING = 'registration_and_licensing',
  DIFFUSION_AND_IMPACT_SUITE = 'diffusion_and_impact_suite',
}

export function overviews(
  tenantId: string,
  params: DashboardV2Params,
  isPublic?: boolean
): Promise<DashboardOverviewData> {
  return request('POST', `/api/t/${tenantId}/dashboards_v2/overviews/`, params, {
    injectTenant: false,
    skipToken: isPublic,
  });
}

export function tables(
  tenantId: string,
  params: DashboardV2Params,
  source: DashboardTableSource
): Promise<DashboardTableData[]> {
  return request('POST', `/api/t/${tenantId}/dashboards_v2/tables/?source=${source}`, params, {
    injectTenant: false,
  });
}

export function tablesExport(tenantId: string, params: any, source: DashboardTableSource): Promise<Blob> {
  const { countries, crops, portofolio_items, ...filteredParams } = params;
  const searchParams = new URLSearchParams({ ...filteredParams, source: source });
  countries.forEach((country: string) => searchParams.append('countries', country));
  crops.forEach((crop: string) => searchParams.append('crops', crop));
  portofolio_items.forEach((portofolio_item: string) =>
    searchParams.append('portofolio_items', portofolio_item)
  );
  return requestRaw(
    'GET',
    `/api/t/${tenantId}/dashboards_v2/tables_export/?${searchParams.toString()}`,
    {},
    {
      injectTenant: false,
    }
  );
}
