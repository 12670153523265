import * as ko from 'knockout';

import { BaseForm } from 'main/screens/base_form';
import { SeedTreatmentSaleData, seedTreatmentSaleApi } from '../api/simple_api';
import { Deferred } from 'main/utils/deferred';
import { createWithComponent } from 'main/utils/ko_utils';
import { SeedTreatmentSale } from '../models/seed_treatment_sale';

let template = require('raw-loader!../../templates/seed_treatment_sale_edit.html').default;

class SeedTreatmentSaleEditScreen extends BaseForm<SeedTreatmentSaleData> {
    entity = ko.observable<SeedTreatmentSale>(null);

    constructor(params: { id: string, result?: Deferred<SeedTreatmentSaleData> }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super(params);

        let request = params.id ? seedTreatmentSaleApi.retrieve(params.id) : undefined;
        let promise = Promise.all([request]).then(([data]) => {
            this.entity(new SeedTreatmentSale(data));
        });
        this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    }

    save = () => {
        if (! this.entity()) {
            return;
        }

        if (this.validateLocal(this.entity)) {
            let data = this.entity().toData();
            this.executeSaveRequest(seedTreatmentSaleApi.save(data)).then((validation) => {
                this.onRemoteValidation(data, this.entity(), validation);
            });
        }
    }
}

export let seedTreatmentSaleEdit = { name: 'seed-treatment-sale-edit', viewModel: createWithComponent(SeedTreatmentSaleEditScreen), template: template };

ko.components.register(seedTreatmentSaleEdit.name, seedTreatmentSaleEdit);
