import * as ko from 'knockout';
import page from 'page';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate, makeYesNoFilter, ListFilter } from 'main/components/list_loader';
import { AssumptionData, assumptionApi, AssumptionFilterData, cropsApi, CropData } from '../api/simple_api';
import { Assumption } from '../models/assumption';
import { session } from 'main/session';
import { downloadBlob, extractId, updateLocationWithQueryString } from 'main/utils';
import { serializeDate } from 'main/api/serialization';
import { getCropSearchConfig } from '../models/helpers/search_configs';
import { translate } from 'main/i18n_text';
import { CountryData, countriesApi } from 'main/api/countries';
import { getCountrySearchConfig } from 'main/components/configs/search_configs';
import { Action } from 'main/components/basic_widgets';
import { addChangelogAction } from 'main/screens/changelog';

let template = require('raw-loader!../../templates/assumptions.html').default;

class AssumptionsFilters {
    country = ko.observable<CountryData>();
    crop = ko.observable<CropData>();
    complete = ko.observable<'all' | 'yes' | 'no'>('all');
    minYear = ko.observable<string>();
    maxYear = ko.observable<string>();

    countrySearchConfig = getCountrySearchConfig(this.country, { disableCreate: true });
    cropSearchConfig = getCropSearchConfig(this.crop);

    filters: ListFilter[] = [
        { name: i18n.t('Country')(), slug: '', type: 'select', config: this.countrySearchConfig },
        { name: i18n.t('Crop')(), slug: '', type: 'select', config: this.cropSearchConfig },
        makeYesNoFilter(i18n.t('Complete')(), this.complete),
        { name: i18n.t('Year')(), slug: '', type: 'numeric', minValue: this.minYear, maxValue: this.maxYear }
    ];

    setInitial(filters: AssumptionFilterData): Promise<void> {
        let country = filters.country ? countriesApi.retrieve(filters.country) : undefined;
        let crop = filters.crop ? cropsApi.retrieve(filters.crop) : undefined;

        return Promise.all([country, crop]).then(([country, crop]) => {
            this.country(country);
            this.crop(crop);
            this.complete(filters.complete || 'all');
            this.minYear(filters.min_year ? filters.min_year.toString() : null);
            this.maxYear(filters.max_year ? filters.max_year.toString() : null);
        });
    }

    toData(history: boolean): AssumptionFilterData {
        return {
            country: extractId(this.country),
            crop: extractId(this.crop),
            complete: this.complete(),
            min_year: this.minYear() ? parseInt(this.minYear(), 10) : null,
            max_year: this.maxYear() ? parseInt(this.maxYear(), 10) : null,
            history
        };
    }
}

class AssumptionsScreen implements ListLoaderDelegate<AssumptionData, Assumption> {
    private assumptionsFilters = new AssumptionsFilters();
    filters = this.assumptionsFilters.filters;
    loading = ko.observable(true);

    canAdd: boolean;
    exporting = ko.observable(false);
    exportingText = i18n.t('Exporting...');
    history: boolean;
    canViewHistory = session.isAtLeastReadOnlyAdmin();

    constructor(params: { history?: boolean, filters: AssumptionFilterData }) {
        this.history = !!params.history;
        this.canAdd = !this.history && session.isAtLeastHead();

        this.assumptionsFilters.setInitial(params.filters).then(() => {
            this.loading(false);
        });
    }

    fetch(params: ListRequestParams) {
        let data = this.assumptionsFilters.toData(this.history);
        updateLocationWithQueryString(data);
        return assumptionApi.list({ ...data, ...params });
    }

    instantiate(data: AssumptionData) {
        return new Assumption(this.history, data);
    }

    getName(entity: Assumption) {
        return entity.country().name + ' ' + translate(entity.crop().name_json) + ' ' + entity.year();
    }

    getEditUrl(entity: Assumption) {
        return !this.history && entity.editUrl;
    }

    remove(id: string) {
        return assumptionApi.remove(id);
    }

    canRemove(entity: Assumption) {
        return !this.history && entity.canEdit();
    }

    exportList = () => {
        this.exporting(true);

        assumptionApi.exportList(this.assumptionsFilters.toData(this.history)).then(data => {
            this.exporting(false);
            downloadBlob(data, 's2bim-assumptions-' + serializeDate(new Date()) + '-.xlsx');
        }).catch(() => {
            this.exporting(false);
        });
    }

    getActions(entity: Assumption): Action[] {
        let actions = addChangelogAction('assumption', entity.id());
        if (this.history) {
            actions.splice(0, 0, {
                icon: 'visibility',
                title: i18n.t('View')(),
                cssClass: '',
                onClick: () => { page(session.toTenantPath(entity.editUrl)); }
            });
        }

        return actions;
    }
}

export let assumptions = { name: 'assumptions', viewModel: AssumptionsScreen, template: template };

ko.components.register(assumptions.name, assumptions);
