import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate, ListFilter } from 'main/components/list_loader';
import { SeedTreatmentData, seedTreatmentApi, SeedTreatmentFiltersData, cropsApi } from '../api/simple_api';
import { SeedTreatment } from '../models/seed_treatment';
import { session } from 'main/session';
import { NameI18nData } from 'main/api/names';
import { getCropSearchConfig } from '../models/helpers/search_configs';
import { extractId, updateLocationWithQueryString } from 'main/utils';
import { addChangelogListAction, addChangelogAction } from 'main/screens/changelog';
import { Action } from 'main/components/basic_widgets';

let template = require('raw-loader!../../templates/seed_treatments.html').default;

class SeedTreatmentsFilters {
    crop = ko.observable<NameI18nData>(null);

    cropSearchConfig = getCropSearchConfig(this.crop);

    filters: ListFilter[];

    constructor() {
        this.filters = [
            { name: i18n.t('Crop')(), slug: '', type: 'select', config: this.cropSearchConfig }
        ];
    }

    setInitial(filters: SeedTreatmentFiltersData): Promise<void> {
        let crop = filters.crop ? cropsApi.retrieve(filters.crop) : undefined;

        return Promise.all([crop]).then(([crop]) => {
            this.crop(crop);
        });
    }

    toData(): SeedTreatmentFiltersData {
        return {
            crop: extractId(this.crop)
        };
    }
}

class SeedTreatmentsScreen implements ListLoaderDelegate<SeedTreatmentData, SeedTreatment> {
    private stFilters = new SeedTreatmentsFilters();
    filters = this.stFilters.filters;

    loading = ko.observable(true);
    canCreate = session.isAdmin();

    listActions = addChangelogListAction('seed_treatment');

    constructor(params: { filters: SeedTreatmentFiltersData }) {
        this.stFilters.setInitial(params.filters).then(() => {
            this.loading(false);
        });
    }

    fetch(params: ListRequestParams) {
        let data = this.stFilters.toData();
        updateLocationWithQueryString(data);
        return seedTreatmentApi.list({ ...data, ...params });
    }

    instantiate(data: SeedTreatmentData) {
        return new SeedTreatment(data);
    }

    getEditUrl(entity: SeedTreatment) {
        return entity.editUrl;
    }

    remove(id: string) {
        return seedTreatmentApi.remove(id);
    }

    canRemove(entity: SeedTreatment) {
        return entity.canEdit();
    }

    getActions(entity: SeedTreatment): Action[] {
        return addChangelogAction('seed_treatment', entity.id());
    }
}

export let seedTreatments = { name: 'seed-treatments', viewModel: SeedTreatmentsScreen, template: template };

ko.components.register(seedTreatments.name, seedTreatments);
