import * as ko from 'knockout';

import { SeedTreatmentData, SeedTreatmentSaleData } from '../api/simple_api';
import { readDecimal, currentYear, emptyToNull } from 'main/utils';
import { session } from 'main/session';
import { getBuyerSearchConfig } from './helpers/search_configs';
import { CountryData } from 'main/api/countries';
import { SeasonData } from 'main/api/seasons';
import { getCountrySearchConfig, getSeasonSearchConfig, getProducerSearchConfig, getProjectSearchConfig, getPISearchConfig } from 'main/components/configs/search_configs';
import { ProducerData, BuyerData } from 'main/api/organizations';
import { ProjectData } from 'main/api/projects';
import { PortofolioItemData } from 'main/api/portofolio_items';

export class SeedTreatmentSale {
    id = ko.observable<string>(null);
    country = ko.observable<CountryData>(null).extend({
        required: true
    });
    seedTreatment = ko.observable<SeedTreatmentData>(null).extend({
        required: true
    });
    seller = ko.observable<ProducerData>(null).extend({
        required: true
    });
    buyer = ko.observable<BuyerData>(null);
    seasonYear = ko.observable<string>(currentYear()).extend({
        required: true,
        digit: true,
        serverError: true
    });
    project = ko.observable<ProjectData>(null).extend({
        required: false
    });
    portofolioItem = ko.observable<PortofolioItemData>(null).extend({
        required: true
    });
    season = ko.observable<SeasonData>(null).extend({ required: true, serverError: true });
    quantity = ko.observable('').extend({
        number: true,
        serverError: true
    });
    projectSearchConfig = getProjectSearchConfig(this.project, {
        disableCreate: true,
        tenantId: session.tenant().slug,
    });
    piSearchConfig = getPISearchConfig(this.portofolioItem, {
        disableCreate: true,
        tenantId: session.tenant().slug,
    });
    quantityForecast = ko.observable('').extend({
        number: true,
        serverError: true
    });
    price = ko.observable('').extend({
        number: true,
        serverError: true
    });
    priceForecast = ko.observable('').extend({
        number: true,
        serverError: true
    });
    canEdit = ko.pureComputed(() => {
        return !this.id()
            || !this.country()
            || !this.seller()
            || session.isAtLeastEditorFor(this.country());
    });

    editUrl: string;

    countrySearchConfig = getCountrySearchConfig(this.country);
    sellerSearchConfig = getProducerSearchConfig(this.seller);
    buyerSearchConfig = getBuyerSearchConfig(this.buyer);
    seasonSearchConfig = getSeasonSearchConfig(this.season, this.country, { disableCreate: true });

    constructor(public data?: SeedTreatmentSaleData) {
        if (data) {
            this.id(data.id);
            this.country(data.country);
            this.seedTreatment(data.seed_treatment);
            this.seller(data.seller);
            this.buyer(data.buyer);
            this.project(data.project);
            this.portofolioItem(data.portofolio_item);
            this.seasonYear(data.season_year.toString());
            this.season(data.season);
            this.quantity(readDecimal(data.quantity));
            this.quantityForecast(readDecimal(data.quantity_forecast));
            this.price(readDecimal(data.price));
            this.priceForecast(readDecimal(data.price_forecast));
        }

        this.editUrl = '/seed_treatment_sales/' + this.id();
    }

    toData(): SeedTreatmentSaleData {
        return {
            id: this.id(),
            country: this.country(),
            seed_treatment: this.seedTreatment(),
            seller: this.seller(),
            buyer: this.buyer(),
            project: this.project(),
            portofolio_item: this.portofolioItem(),
            season_year: parseInt(this.seasonYear(), 10),
            season: this.season(),
            quantity: emptyToNull(this.quantity()),
            quantity_forecast: emptyToNull(this.quantityForecast()),
            price: emptyToNull(this.price()),
            price_forecast: emptyToNull(this.priceForecast())
        };
    }
}
