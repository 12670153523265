require('es6-promise').polyfill();
require('config.js');
require('./ts/index.ts');
require('./styles/index.less');
require('main_styles/vendor/materialize.css');
require('main_js/vendor/materialize.js');
require('main_js/vendor/picker.js'); // must come after materialize, to override the one included there
require('main_js/vendor/picker.date.js');
require('favicon.ico');
require('product_images/logo/logo_email.png');
