import * as ko from 'knockout';
import * as i18n from 'main/i18n'; i18n;

import { setupErrorLogging } from 'main/error_logging';
setupErrorLogging();

// force to import files that are used for global side effects (e.g. registering a KO binding/component)
import * as session from 'main/session'; session;
import * as koGoogleSignIn from 'main/ko_bindings/google_sign_in'; koGoogleSignIn;
import * as koValidationError from 'main/ko_bindings/validation_error'; koValidationError;
import * as koMaterialDropdown from 'main/ko_bindings/material_dropdown'; koMaterialDropdown;
import * as koMaterialSelect from 'main/ko_bindings/material_select'; koMaterialSelect;
import * as koDatePicker from 'main/ko_bindings/date_picker'; koDatePicker;
import * as koTenantHref from 'main/ko_bindings/tenant_href'; koTenantHref;
import * as koValidation from 'knockout.validation'; koValidation;
import * as geoPtValidation from 'main/ko_bindings/geo_pt_validation'; geoPtValidation;
import * as koFormattedDate from 'main/ko_bindings/formatted_date'; koFormattedDate;
import * as koTimeAgo from 'main/ko_bindings/time_ago'; koTimeAgo;
import * as koFormattedBoolean from 'main/ko_bindings/formatted_boolean'; koFormattedBoolean;
import * as koFormattedNumber from 'main/ko_bindings/formatted_number'; koFormattedNumber;
import * as koBarChart from './ko_bindings/bar_chart'; koBarChart;
import * as koChart from './ko_bindings/chart'; koChart;
import * as koPercentBorder from './ko_bindings/percent_border'; koPercentBorder;
import * as koI18nText from 'main/ko_bindings/i18n_text'; koI18nText;
import * as koOnClickOutside from 'main/ko_bindings/on_click_outside'; koOnClickOutside;
import * as koTextAreaAutoResize from 'main/ko_bindings/text_area_auto_resize'; koTextAreaAutoResize;
import * as koFirebaseLogin from 'main/ko_bindings/firebase_login'; koFirebaseLogin;
import * as basicWidgets from 'main/components/basic_widgets'; basicWidgets;
import * as formSelectSearch from 'main/components/form_select_search'; formSelectSearch;
import * as formDateTimeInput from 'main/components/form_date_time_input'; formDateTimeInput;
import * as formSelectCV from 'main/components/form_select_cv'; formSelectCV;
import * as formSelectST from './components/form_select_st'; formSelectST;
import * as listLoader from 'main/components/list_loader'; listLoader;
import * as listHeader from 'main/components/list_header'; listHeader;
import * as baseDimensionRecordEdit from 'main/components/base_dimension_record_edit'; baseDimensionRecordEdit;
import * as i18nTextInput from 'main/components/i18n_text_input'; i18nTextInput;
import * as importEntities from 'main/components/import_entities'; importEntities;
import * as attributeValue from 'main/components/attribute_value'; attributeValue;
import * as dimensionAdvancedSearch from 'main/components/dimension_advanced_search'; dimensionAdvancedSearch;
import * as columnSelect from 'main/components/columns_select'; columnSelect;
import * as userLog from 'main/components/user_log'; userLog;
import { setupRoutes } from './routes';
import { setupApp } from 'main/app';
import { getMenu } from './menu';


ko.validation.init({
  insertMessages: false
});

i18n.default.setupKnockoutValidationLocalization();

setupRoutes();
setupApp(getMenu);

window.addEventListener('DOMContentLoaded', () => {
  session.session.init({
    apiKey: SERVER_INFO.FIREBASE_API_KEY,
    authDomain: SERVER_INFO.FIREBASE_AUTH_DOMAIN,
  });
}, { once: true });

// DEBUG
(<any>window).ko = ko;
