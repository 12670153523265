import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate, ListLoader } from 'main/components/list_loader';
import { TaskData, tasksApi } from '../api/simple_api';
import { session } from 'main/session';
import { confirmDialog } from 'main/components/confirm_dialog';
import { closeCompleted, setClosed, sendNotifications, sendNotification, TaskUserType } from '../api/tasks';
import { Action } from 'main/components/basic_widgets';
import { refreshObservableArrayItem } from 'main/utils';
import { translate } from 'main/i18n_text';

let template = require('raw-loader!../../templates/tasks.html').default;

export function operationName(entity: TaskData): string {
    let op = {
        sale: i18n.t('Sale')(),
        production: i18n.t('Production')()
    };
    let target = {
        forecast: i18n.t('Forecast')(),
        actual_data: i18n.t('Actual data')()
    };

    return op[entity.operation] + ' (' + target[entity.target] + ')';
}

class TasksScreen implements ListLoaderDelegate<TaskData, TaskData> {
    loader: ListLoader<TaskData, TaskData>;

    closingText = i18n.t('Closing...')();
    closing = ko.observable(false);
    canCloseCompleted = session.isAtLeastEditor();

    notifyingText = i18n.t('Sending notification emails...')();
    notifying = ko.observable(false);
    canNotify = session.isAtLeastEditor();

    canCreate = session.isAtLeastEditor();

    forUserType: TaskUserType;
    newUrl: string;

    constructor(params: { forUserType: TaskUserType }) {
        this.forUserType = params.forUserType;

        if (this.forUserType === 'producer') {
            this.newUrl = '/tasks/new/';
        } else if (this.forUserType === 'buyer') {
            this.newUrl = '/buyer_tasks/new/';
        } else {
            this.newUrl = '/user_tasks/new/';
        }
    }

    onReady(loader: ListLoader<TaskData, TaskData>) {
        this.loader = loader;
    }

    fetch(params: ListRequestParams) {
        return tasksApi.list({ for_user_type: this.forUserType, ...params });
    }

    instantiate(data: TaskData) {
        return data;
    }

    getName(entity: TaskData): string {
        let name = (entity.user || entity.producer || entity.buyer).name;
        return translate(entity.season.name_json) + '/' + entity.season_year
            + ', ' + name
            + ', ' + translate(entity.crop_variety.name_json)
            + ', ' + this.operationName(entity);
    }

    getEditUrl(entity: TaskData) {
        return '';
    }

    remove(id: string) {
        return tasksApi.remove(id);
    }

    canRemove(entity: TaskData) {
        let country = (entity.producer || entity.buyer).country;
        if (entity.user) {
            return session.isAtLeastHeadFor(country);
        } else {
            return session.isAtLeastEditorFor(country);
        }
    }

    operationName = operationName;

    closeCompleted = () => {
        let title = i18n.t('Warning')();
        let message: string;
        if (this.forUserType === 'producer') {
            message = i18n.t('This will close all the completed tasks, which will no longer be editable by producers. Do you want to continue?')();
        } else if (this.forUserType === 'buyer') {
            message = i18n.t('This will close all the completed tasks, which will no longer be editable by buyers. Do you want to continue?')();
        } else {
            message = i18n.t('This will close all the completed tasks, which will no longer be editable by users. Do you want to continue?')();
        }

        confirmDialog(title, message).then(() => {
            this.closing(true);
            return closeCompleted(this.forUserType);
        }).then(() => {
            this.closing(false);
            if (this.loader) {
                this.loader.refresh();
            }
        }).catch(() => {
            this.closing(false);
        });
    }

    notify = () => {
        let title = i18n.t('Confirm')();
        let message: string;
        if (this.forUserType === 'producer') {
            message = i18n.t('You\'re about to send a notification email to all producers with new tasks. Do you want to continue?')();
        } else if (this.forUserType === 'buyer') {
            message = i18n.t('You\'re about to send a notification email to all buyers with new tasks. Do you want to continue?')();
        } else {
            message = i18n.t('You\'re about to send a notification email to all users with new tasks. Do you want to continue?')();
        }

        confirmDialog(title, message).then(() => {
            this.notifying(true);
            return sendNotifications(this.forUserType);
        }).then(() => {
            this.notifying(false);
        }).catch(() => {
            this.notifying(false);
        });
    }

    private notifySingle(entity: TaskData) {
        let title = i18n.t('Confirm')();
        let message = i18n.t('You\'re about to send a notification email for this task. Do you want to continue?')();

        confirmDialog(title, message).then(() => {
            return sendNotification(entity.id, this.forUserType);
        });
    }

    getActions(entity: TaskData): Action[] {
        let canEdit = session.isAtLeastEditorFor((entity.producer || entity.buyer).country);
        let res: Action[] = [];

        if (canEdit) {
            if (entity.closed) {
                res.push({
                    icon: 'phonelink',
                    title: i18n.t('Reopen')(),
                    cssClass: '',
                    onClick: () => { this.setClosed(entity, false); }
                });
            } else {
                res.push({
                    icon: 'phonelink_off',
                    title: i18n.t('Close')(),
                    cssClass: '',
                    onClick: () => { this.setClosed(entity, true); }
                });
            }

            res.push({
                icon: 'email',
                title: i18n.t('Send notification')(),
                cssClass: '',
                onClick: () => { this.notifySingle(entity); }
            });
        }

        return res;
    }

    setClosed(entity: TaskData, closed: boolean) {
        setClosed(entity.id, closed).then(() => {
            entity.closed = closed;
            refreshObservableArrayItem(this.loader.items, entity);
        });
    }
}

export let tasks = { name: 'tasks', viewModel: TasksScreen, template: template };

ko.components.register(tasks.name, tasks);
