import * as ko from 'knockout';

import { SeedTreatmentData } from '../api/simple_api';
import { getCropSearchConfig } from './helpers/search_configs';
import { NameI18nData } from 'main/api/names';
import { session } from 'main/session';
import { ProducerData } from 'main/api/organizations';
import { getProducerSearchConfig } from 'main/components/configs/search_configs';
import { readDecimal } from 'main/utils';

export class SeedTreatment {
    id = ko.observable<string>(null);
    name = ko.observable('').extend({
        required: true,
        serverError: true
    });
    crop = ko.observable<NameI18nData>(null).extend({
        required: true
    });
    owner = ko.observable<ProducerData>(null);
    yieldGain = ko.observable<string>('').extend({
        number: true,
        serverError: true,
        required: true
    });
    applicationRate = ko.observable<string>('').extend({
        number: true,
        serverError: true,
        required: true
    });
    canEdit = session.isAdmin;

    cropSearchConfig = getCropSearchConfig(this.crop);
    ownerSearchConfig = getProducerSearchConfig(this.owner);

    editUrl: string;

    constructor(public data?: SeedTreatmentData) {
        if (data) {
            this.id(data.id);
            this.name(data.name);
            this.crop(data.crop);
            this.owner(data.owner);
            this.yieldGain(readDecimal(data.yield_gain));
            this.applicationRate(readDecimal(data.application_rate));
        }

        this.editUrl = '/seed_treatments/' + this.id();
    }

    toData(): SeedTreatmentData {
        return {
            id: this.id(),
            name: this.name(),
            crop: this.crop(),
            owner: this.owner(),
            yield_gain: this.yieldGain(),
            application_rate: this.applicationRate()
        };
    }
}
