import * as ko from 'knockout';

import { Chart, ChartConfiguration } from 'chart.js';

function setup(element: Element, config: ChartConfiguration): Chart {
  return new Chart(<HTMLCanvasElement>element, config);
}

ko.bindingHandlers['chart'] = {
  init: (element: Element, valueAccessor: () => KnockoutObservable<ChartConfiguration>) => {
      renderGraph(element, valueAccessor);
  },
  update: (element: Element, valueAccessor: () => KnockoutObservable<ChartConfiguration>) => {
      renderGraph(element, valueAccessor);
  },
};

function renderGraph(element: Element, valueAccessor: () => KnockoutObservable<ChartConfiguration>) {
  if ((element as any)['chart']) {
    (element as any)['chart'].destroy();
  }
  const chart = setup(element, ko.unwrap(valueAccessor()));
  (element as any)['chart'] = chart;
}