import * as ko from 'knockout';
import page from 'page';

import { BaseForm } from 'main/screens/base_form';
import { session } from 'main/session';
import i18n from 'main/i18n';


let template = require('raw-loader!../../templates/settings.html').default;

class SettingsScreen extends BaseForm<{}> {

    languageList = SERVER_INFO.LANGUAGE_LIST;
    defaultLanguage = i18n.getUserPreferedLanguage();

    constructor() {
        super({});
        this.loaded();
    }

    save = () => {
        if (this.defaultLanguage !== i18n.getUserPreferedLanguage()) {
            i18n.setUserPreferedLanguage(this.defaultLanguage);
            page.stop(); // we need the browser to *really* refresh
            location.href = session.toTenantPath('/');
        } else {
            this.close();
        }
    }
}

export let settings = { name: 'settings', viewModel: SettingsScreen, template: template };

ko.components.register(settings.name, settings);
