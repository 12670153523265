import page from 'page';
import * as ko from 'knockout';

import { BaseForm } from 'main/screens/base_form';
import { User, s2bimUserOptions } from 'main/models/user';
import * as usersApi from 'main/api/users';
import { Deferred } from 'main/utils/deferred';
import { createWithComponent } from 'main/utils/ko_utils';
import { session } from 'main/session';

let template = require('raw-loader!../../templates/user_edit.html').default;

class UserEditScreen extends BaseForm<usersApi.UserData> {
    user = ko.validatedObservable<User>(null);
    globalError = ko.observable('');

    constructor(params: { id: string, initialName?: string, result?: Deferred<usersApi.UserData> }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super(params);

        let userDataPromise = params.id ? usersApi.retrieve(params.id) : undefined;
        let promise = Promise.all([userDataPromise]).then(([userData]) => {
            this.user(new User(s2bimUserOptions, userData));

            if (!userData && params.initialName) {
                this.user().name(params.initialName);
            }
        });
        this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    }

    save = () => {
        this.globalError('');

        if (this.validateLocal(this.user)) {
            let data = this.user().toData();
            this.executeSaveRequest(usersApi.save(data)).then((validation) => {
                this.onRemoteValidation(data, this.user(), validation);

                if (!validation.isValid && validation.errors['__all__']) {
                    this.globalError(validation.errors['__all__'].join('. '));
                }

                if (validation.isValid && session.getEmail() === data.email) {
                    // Edited the current user, role might have changed. Reload the page.
                    page.stop();
                    location.href = session.toTenantPath('/users/');
                }
            });
        }
    }
}

export let userEdit = { name: 'user-edit', viewModel: createWithComponent(UserEditScreen), template: template };

ko.components.register(userEdit.name, userEdit);
