import * as ko from 'knockout';

import { session } from '../session';
import { OrganizationalRegionData, orgRegionApi } from '../api/org_regions';
import { ListLoaderDelegate } from '../components/list_loader';
import { ListRequestParams } from '../api/request';
import { addChangelogListAction, addChangelogAction } from './changelog';
import { Action } from '../components/basic_widgets';

let template = require('raw-loader!../../templates/organizational_regions.html').default;

class OrgRegionsScreen implements ListLoaderDelegate<OrganizationalRegionData> {
  canCreate = session.isAdmin();

  listActions = addChangelogListAction('organizational_region');

  fetch(params: ListRequestParams) {
    return orgRegionApi.list(params);
  }

  instantiate(data: OrganizationalRegionData) {
    return data;
  }

  getEditUrl(entity: OrganizationalRegionData) {
    return '/regions/' + entity.id + '/';
  }

  remove(id: string) {
    return orgRegionApi.remove(id);
  }

  canRemove(name: OrganizationalRegionData) {
    return session.isAdmin();
  }

  getActions(entity: OrganizationalRegionData): Action[] {
    return addChangelogAction('organizational_region', entity.id);
  }
}

export let organizationalRegions = {
  name: 'organizational-regions',
  viewModel: OrgRegionsScreen,
  template: template,
};

ko.components.register(organizationalRegions.name, organizationalRegions);
