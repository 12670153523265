import { session } from 'main/session';
import { NavGroup } from 'main/components/navigation_bar';
import i18n from 'main/i18n';
import { canViewChangelog } from 'main/permissions';

export function getMenu() {
    let groups: NavGroup[] = [];

    let isPartner = session.isPartner();
    let isCompany = session.isCompany();
    let isProducer = session.isProducer();
    let isAtLeastHead = session.isAtLeastHead();
    let isAtLeastEditor = session.isAtLeastEditor();
    let isAtLeastReadOnlyAdmin = session.isAtLeastReadOnlyAdmin();

    if (!isPartner) {
        groups = [
            new NavGroup('', [{ title: i18n.t('Dashboard')(), icon: 'show_chart', href: '/dashboards/internal/', important: true }]),
        ];
    }

    if (!isCompany || isProducer) {
        groups.push(new NavGroup('', [{ title: i18n.t('Productions & Sales')(), icon: 'grain', href: '/productions/', important: true }]));
    }

    groups.push(new NavGroup('', [{ title: i18n.t('Other seeds sales')(), icon: 'receipt', href: '/sales/', important: true }]));

    if (isAtLeastEditor || isAtLeastReadOnlyAdmin) {
        groups.push(new NavGroup(i18n.t('Crop')(), [
            { title: i18n.t('Crops')(), icon: 'local_florist', href: '/crops/' },
            { title: i18n.t(['crop_varieties_title', 'Crop Varieties'])(), icon: 'local_florist', href: '/crop_varieties/' }
        ]));
    }

    if (isAtLeastReadOnlyAdmin) {
        groups.push(new NavGroup(i18n.t('Seed Treatments')(), [
            { title: i18n.t('Seed Treatments')(), icon: 'grain', href: '/seed_treatments/' },
            { title: i18n.t('Seed Treatment Sales')(), icon: 'receipt', href: '/seed_treatment_sales/' }
        ]));
    } else if (!isCompany && !isPartner) {
        groups.push(new NavGroup('', [{ title: i18n.t('Seed Treatment Sales')(), icon: 'receipt', href: '/seed_treatment_sales/' }]));
    }

    let orgs = new NavGroup(i18n.t('Organizations')(), [
        { title: i18n.t('Buyers')(), icon: 'shopping_basket', href: '/buyers/' },
        { title: i18n.t('Producers/Sellers')(), icon: 'store', href: '/producers/' }
    ]);

    if (isAtLeastReadOnlyAdmin) {
        orgs.items.push({ title: i18n.t('Organization Types')(), icon: 'business', href: '/organization_types/' });
    }

    if (!isCompany && !isPartner) {
        groups.push(orgs);
    }

    if (!isCompany && !isPartner) {
        let subgroup = new NavGroup(i18n.t('Countries, Regions, PIs')(), [
            { title: i18n.t('Countries')(), icon: 'language', href: '/countries/' },
            { title: i18n.t('Organizational Regions')(), icon: 'location_city', href: '/regions/' }
        ]);
        if (isAtLeastReadOnlyAdmin || isAtLeastEditor) {
            subgroup.items.push({ title: i18n.t('Portofolio Items')(), icon: 'event', href: '/portofolio_items/' });
        }
        groups.push(subgroup);
    }

    if (!isPartner) {
        if (isCompany) {
            if (isProducer) {
                groups.push(new NavGroup('', [{ title: i18n.t('Licenses')(), icon: 'verified_user', href: '/licenses/', important: true }]));
            }
        } else {
            let regs = new NavGroup(i18n.t('Registrations and Licenses')(), [
                { title: i18n.t('Registrations')(), icon: 'card_membership', href: '/registrations/' },
                { title: i18n.t('Licenses')(), icon: 'verified_user', href: '/licenses/' }
            ]);

            groups.push(regs);
            groups.push(new NavGroup('', [{ title: i18n.t('Assumptions')(), icon: 'perm_data_setting', href: '/assumptions/', important: true }]));
            groups.push(new NavGroup('', [{ title: i18n.t('Seasons')(), icon: 'calendar_today', href: '/seasons/' }]));
        }
    }

    if (isAtLeastHead) {
        groups.push(new NavGroup('', [{ title: i18n.t('Users')(), icon: 'person', href: '/users/' }]));
    }

    if (canViewChangelog(null)) {
        groups.push(new NavGroup('', [{ title: i18n.t('Changelog')(), icon: 'history', href: '/changelog/' }]));
    }

    return groups;
}
