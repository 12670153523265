import i18n from 'main/i18n';
import { FormSelectSearchConfiguration } from 'main/components/form_select_search';
import { cropVarietyApi, cropsApi, SeedTreatmentData, seedTreatmentApi, CropData } from '../../api/simple_api';
import { session } from 'main/session';
import { SeasonData, list as seasonList } from 'main/api/seasons';
import { NameData, NameI18nData } from 'main/api/names';
import { UserData, list as listUsers } from 'main/api/users';
import { orgRegionApi } from 'main/api/org_regions';
import { translate } from 'main/i18n_text';
import { projectApi } from 'main/api/projects';
import { KOMaybeArray, MaybeKO, isKOArray } from 'main/utils/ko_utils';
import { CountryData } from 'main/api/countries';
import { BuyerData, buyersApi } from 'main/api/organizations';
import { PartnerCropVarietyData } from 'main/api/crop_varieties';
import { canEditCrop } from 'main/permissions';
import { CROP_SLUG } from 'main/api/dimension_metas';

interface Options {
    tenantId?: string;
    disableCreate?: boolean;
    publicList?: boolean;
}

interface CountryFilteredOptions extends Options {
    country?: MaybeKO<CountryData>;
}

function addTarget<T>(
    observable: KOMaybeArray<T>,
    config: FormSelectSearchConfiguration<T>
  ): FormSelectSearchConfiguration<T> {
    if (isKOArray(observable)) {
      config.entities = observable;
    } else {
      config.entity = observable;
    }
  
    return config;
}

function getCountryId(options: CountryFilteredOptions | undefined) {
    let country = options && options.country && ko.unwrap(options.country);
    return country && country.id;
}

export function getSeedTreatmentSearchConfig(observable: KnockoutObservable<SeedTreatmentData>, crop?: KnockoutObservable<NameI18nData>, options?: Options): FormSelectSearchConfiguration<SeedTreatmentData> {
    let config: FormSelectSearchConfiguration<SeedTreatmentData> = {
        getSummaryName: (entity) => {
            return translate(entity.crop.name_json) + ' - ' + entity.name;
        },
        list: (params) => {
            if (crop && crop()) {
                return seedTreatmentApi.list({ crop: crop().id, ...params }, options);
            } else {
                return seedTreatmentApi.list(params, options);
            }
        },
        entity: observable
    };

    if (!(options && options.disableCreate) && session.isAdmin()) {
        config.create = {
            title: i18n.t('Seed treatment')(),
            componentName: 'seed-treatment-edit',
            extraParams: {
                initialCrop: crop
            }
        };
    }

    return config;
} 

export function getRegionSearchConfig(observable: KnockoutObservable<NameData>, options?: Options): FormSelectSearchConfiguration<NameData> {
    let config: FormSelectSearchConfiguration<NameData> = {
        getSummaryName: (region) => {
            return region.name;
        },
        list: (params) => {
            return orgRegionApi.list({ role: 'editor', ...params }, options);
        },
        entity: observable
    };

    if (!(options && options.disableCreate) && session.isAdmin()) {
        config.create = {
            title: i18n.t('Organizational Region')(),
            componentName: 'org-region-edit'
        };
    }

    return config;
}

export function getCropSearchConfigCropData(observable: KOMaybeArray<CropData>, options?: Options): FormSelectSearchConfiguration<CropData> {
    let config: FormSelectSearchConfiguration<CropData> = {
        getSummaryName: (entity) => translate(entity.name_json),
        list: (params) => cropsApi.list(params, options),
        advancedSearch: session.isAtLeastStaff() && {
            componentName: 'dimension-advanced-search',
            extraParams: { dimensionMetaId: CROP_SLUG },
            instantiate: (data: CropData) => data,
            serialize: (data) => data,
        },
    };

    if (canEditCrop() && !(options && options.disableCreate)) {
        config.create = {
            title: i18n.t('Crop')(),
            componentName: 'crop-edit',
        };
    }

    return addTarget(observable, config);
}

export function getSeasonSearchConfig(
    observable: KnockoutObservable<SeasonData>,
    options?: Options
) {
    let config: FormSelectSearchConfiguration<SeasonData> = {
        getSummaryName: (entity) => entity.name_json,
        list: (params) => {
            return seasonList(
                {
                    country_id: undefined,
                    ...params,
                }
            );
        },
        entity: observable,
    };

    if(!(options && options.disableCreate)) {
        config.create = {
            title: i18n.t('Season')(),
            componentName: 'season-edit'
        }
    }

    return config;
}

export function getCropSearchConfig(observable: KnockoutObservable<NameI18nData>, options?: Options): FormSelectSearchConfiguration<NameI18nData> {
    return {
        getSummaryName: (entity) => {
            return translate(entity.name_json);
        },
        list: (params) => {
            return cropsApi.list(params, options);
        },
        entity: observable
    };
}

export function getProjectSearchConfig(
    observable: KnockoutObservable<NameData>,
    options?: Options
): FormSelectSearchConfiguration<NameData> {
    let config: FormSelectSearchConfiguration<NameData> = {
        getSummaryName: (entity) => entity.name,
        list: (params) => {
            return projectApi.list(params, options);
        },
        entity: observable,
    };

    if(!(options && options.disableCreate)) {
        config.create = {
            title: i18n.t('Project')(),
            componentName: 'project-edit',
        }
    }

    return config;
}

export function getCropVarietySearchConfig(observable: KnockoutObservable<PartnerCropVarietyData>, crop?: KnockoutObservable<NameI18nData>, options?: Options): FormSelectSearchConfiguration<PartnerCropVarietyData> {
    return {
        getSummaryName: (cropVariety) => {
            return translate(cropVariety.name_json);
        },
        list: (params) => {
            if (crop && crop()) {
                return cropVarietyApi.list({ crop_ids: [crop().id], ...params }, options);
            } else {
                return cropVarietyApi.list(params, options);
            }
        },
        entity: observable
    };
}

export function getCropVarietySearchConfigForMultipleCrops(observable: KnockoutObservable<PartnerCropVarietyData>, crop_ids?: string[], options?: Options): FormSelectSearchConfiguration<PartnerCropVarietyData> {
    return {
        getSummaryName: (cropVariety) => {
            return translate(cropVariety.name_json);
        },
        list: (params) => {
            if (crop_ids && crop_ids.length > 0) {
                return cropVarietyApi.list({ crop_ids: crop_ids, ...params}, options);
            } else {
                return cropVarietyApi.list(params, options);
            }
        },
        entity: observable
    };
}

export function getBuyerSearchConfig(observable: KnockoutObservable<BuyerData>, options?: CountryFilteredOptions): FormSelectSearchConfiguration<BuyerData> {
    let config: FormSelectSearchConfiguration<BuyerData> = {
        getSummaryName: (entity) => {
            return entity.name;
        },
        list: (params) => {
            return buyersApi.list({ role: 'editor', country_id: getCountryId(options), ...params }, options);
        },
        entity: observable
    };

    if ((session.isAtLeastEditor() || session.isCompany()) && !(options && options.disableCreate)) {
        config.create = {
            title: i18n.t('Buyer')(),
            componentName: 'buyer-edit'
        };
    }

    return config;
}

export function getUserSearchConfig(observable: KnockoutObservable<UserData>): FormSelectSearchConfiguration<UserData> {
    return {
        getSummaryName: (entity) => {
            return entity.name;
        },
        list: (params) => {
            return listUsers(params);
        },
        entity: observable
    };
}
