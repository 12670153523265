import * as ko from 'knockout';

import { SeedTreatmentData, seedTreatmentApi } from '../api/simple_api';
import { createWithComponent } from 'main/utils/ko_utils';
import { SeedTreatment } from '../models/seed_treatment';
import { BaseEditScreen, BaseEditParams } from 'main/screens/base_edit';
import { NameI18nData } from 'main/api/names';

let template = require('raw-loader!../../templates/seed_treatment_edit.html').default;

class SeedTreatmentEditScreen extends BaseEditScreen<SeedTreatmentData, SeedTreatment> {
    constructor(private params: BaseEditParams<SeedTreatmentData> & { initialCrop?: KnockoutObservable<NameI18nData> }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super({ api: seedTreatmentApi, focus: true }, params, componentInfo);
    }

    instantiate(data: SeedTreatmentData) {
        let res = new SeedTreatment(data);
        if (this.params.initialCrop) {
            res.crop(this.params.initialCrop());
        }
        return res;
    }
}

export let seedTreatmentEdit = { name: 'seed-treatment-edit', viewModel: createWithComponent(SeedTreatmentEditScreen), template: template };

ko.components.register(seedTreatmentEdit.name, seedTreatmentEdit);
