import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate, ListFilter } from 'main/components/list_loader';
import { SaleData, saleApi, SalesFiltersData, cropsApi, cropVarietyApi } from '../api/simple_api';
import { retrieve } from 'main/api/seasons';
import { Sale } from '../models/sale';
import { session } from 'main/session';
import { NameI18nData } from 'main/api/names';
import { getCropSearchConfig, getCropVarietySearchConfig, getBuyerSearchConfig } from '../models/helpers/search_configs';
import { extractId, updateLocationWithQueryString, tryFormatDate, downloadBlob } from 'main/utils';
import { translate } from 'main/i18n_text';
import { CountryData, countriesApi } from 'main/api/countries';
import { getCountrySearchConfig, getProducerSearchConfig, getSeasonSearchConfig, getPISearchConfig } from 'main/components/configs/search_configs';
import { ProducerData, BuyerData, producersApi, buyersApi } from 'main/api/organizations';
import { PartnerCropVarietyData } from 'main/api/crop_varieties';
import { Action } from 'main/components/basic_widgets';
import { addChangelogAction, addChangelogListAction } from 'main/screens/changelog';
import { SeasonData } from 'main/api/seasons';
import { PortofolioItemData, portofolioItemApi } from 'main/api/portofolio_items';

let template = require('raw-loader!../../templates/sales.html').default;

export class SalesFilters {
    country = ko.observable<CountryData>();
    crop = ko.observable<NameI18nData>();
    cropVariety = ko.observable<PartnerCropVarietyData>();
    producer = ko.observable<ProducerData>(null);
    buyer = ko.observable<BuyerData>(null);
    minYear = ko.observable<string>();
    maxYear = ko.observable<string>();
    season = ko.observable<SeasonData>();
    portofolioItem = ko.observable<PortofolioItemData>();

    countrySearchConfig = getCountrySearchConfig(this.country, { disableCreate: true, role: 'viewer' });
    cropSearchConfig = getCropSearchConfig(this.crop);
    cropVarietySearchConfig = getCropVarietySearchConfig(this.cropVariety, this.crop);
    producerSearchConfig = getProducerSearchConfig(this.producer, { disableCreate: true });
    buyerSearchConfig = getBuyerSearchConfig(this.buyer, { disableCreate: true });
    seasonSearchConfig = getSeasonSearchConfig(this.season);
    piSearchConfig = getPISearchConfig(this.portofolioItem, {
        disableCreate: true
    });

    filters: ListFilter[];

    constructor(params: { producerTitle: string, includeBuyer: boolean, includeSeason?: boolean }) {
        this.filters = [
            { name: i18n.t('Country')(), slug: '', type: 'select', config: this.countrySearchConfig },
            { name: i18n.t('Crop')(), slug: '', type: 'select', config: this.cropSearchConfig },
            { name: i18n.t('Crop variety')(), slug: '', type: 'select', config: this.cropVarietySearchConfig },
            { name: params.producerTitle, slug: '', type: 'select', config: this.producerSearchConfig },
            { name: i18n.t('Year')(), slug: '', type: 'numeric', minValue: this.minYear, maxValue: this.maxYear },
            { name: i18n.t('PI')(), slug: '', type: 'select', config: this.piSearchConfig },
        ];

        if (params.includeBuyer) {
            this.filters.splice(4, 0, { name: i18n.t('Buyer')(), slug: '', type: 'select', config: this.buyerSearchConfig });
        }

        if (params.includeSeason) {
            this.filters.splice(4, 0, { name: i18n.t('Season')(),  slug: '', type: 'select', config: this.seasonSearchConfig });
        }
    }

    setInitial(filters: SalesFiltersData): Promise<void> {
        let country = filters.country ? countriesApi.retrieve(filters.country) : undefined;
        let crop = filters.crop ? cropsApi.retrieve(filters.crop) : undefined;
        let cropVariety = filters.crop_variety ? cropVarietyApi.retrieve(filters.crop_variety) : undefined;
        let producer = filters.producer ? producersApi.retrieve(filters.producer) : undefined;
        let buyer = filters.buyer ? buyersApi.retrieve(filters.buyer) : undefined;
        let season = filters.season ? retrieve(filters.season) : undefined;
        let portofolioItem = filters.portofolio_item ? portofolioItemApi.retrieve(filters.portofolio_item) : undefined;

        return Promise.all([country, crop, cropVariety, producer, buyer, season, portofolioItem]).then(([country, crop, cropVariety, producer, buyer, season, portofolioItem]) => {
            this.country(country);
            this.crop(crop);
            this.cropVariety(cropVariety);
            this.producer(producer);
            this.buyer(buyer);
            this.season(season),
            this.portofolioItem(portofolioItem);
            this.minYear(filters.min_year ? filters.min_year.toString() : null);
            this.maxYear(filters.max_year ? filters.max_year.toString() : null);
        });
    }

    toData(): SalesFiltersData {
        return {
            country: extractId(this.country),
            crop: extractId(this.crop),
            crop_variety: extractId(this.cropVariety),
            producer: extractId(this.producer),
            buyer: extractId(this.buyer),
            min_year: this.minYear() ? parseInt(this.minYear(), 10) : null,
            max_year: this.maxYear() ? parseInt(this.maxYear(), 10) : null,
            exclude_forecasts: false,
            season: extractId(this.season),
            portofolio_item: extractId(this.portofolioItem),
        };
    }
}

class SalesScreen implements ListLoaderDelegate<SaleData, Sale> {
    private salesFilters = new SalesFilters({
        producerTitle: i18n.t('Seller')(),
        includeBuyer: true
    });
    filters = this.salesFilters.filters;
    loading = ko.observable(true);
    private exporting = ko.observable(false);

    isCompany = session.isCompany();
    canCreate = session.isAtLeastEditor();

    private onExport = async () => {
        this.exporting(true);
        try {
            let blob = await saleApi.exportList(this.salesFilters.toData());
            downloadBlob(blob, 'sales.xlsx');
        } finally {
            this.exporting(false);
        }
    };

    listActions = addChangelogListAction('sale', [
        {
            title: i18n.t('Export')(),
            icon: 'file_download',
            onClick: this.onExport,
            loading: this.exporting
        }
    ]);

    constructor(params: { filters: SalesFiltersData }) {
        this.salesFilters.setInitial(params.filters).then(() => {
            this.loading(false);
        });
    }

    fetch(params: ListRequestParams) {
        let data = this.salesFilters.toData();
        updateLocationWithQueryString(data);
        return saleApi.listImports({ ...data, ...params });
    }

    instantiate(data: SaleData) {
        return new Sale(data);
    }

    getName(entity: Sale) {
        let date = tryFormatDate(entity.saleDate());
        return entity.country().name + ', ' + translate(entity.cropVariety().name_json) + ', ' + entity.seller().name + ', ' + date;
    }

    getEditUrl(entity: Sale) {
        return entity.editUrl;
    }

    remove(id: string) {
        return saleApi.remove(id);
    }

    canRemove(entity: Sale) {
        return entity.canEdit();
    }

    getActions(entity: Sale): Action[] {
        return addChangelogAction('sale', entity.id());
    }
}

export let sales = { name: 'sales', viewModel: SalesScreen, template: template };

ko.components.register(sales.name, sales);
