import * as ko from 'knockout';

import { OrganizationalRegionData } from '../api/org_regions';
import { session } from '../session';

export class OrganizationalRegion {
  id = ko.observable<string>(null);
  name = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  canEditName = session.isAdmin;

  editUrl: string;

  constructor(data?: OrganizationalRegionData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
    }

    this.editUrl = '/regions/' + this.id();
  }

  toData(): OrganizationalRegionData {
    return {
      id: this.id(),
      name: this.name(),
    };
  }
}
