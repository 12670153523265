import * as ko from 'knockout';

import { BaseForm } from 'main/screens/base_form';
import { Production } from '../models/production';
import { ProductionData, productionApi, tasksApi } from '../api/simple_api';
import { Deferred } from 'main/utils/deferred';
import { createWithComponent } from 'main/utils/ko_utils';
import { setGlobalError } from 'main/screens/base_edit';

let template = require('raw-loader!../../templates/production_edit.html').default;

class ProductionEditScreen extends BaseForm<ProductionData> {
    entity = ko.observable<Production>(null);
    globalError = ko.observable('');

    constructor(params: { id: string, taskId?: string, result?: Deferred<ProductionData>, type?: 'actual' | 'forecast' | 'both' }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super(params);

        let request = params.id ? productionApi.retrieve(params.id) : undefined;
        let taskReq = params.taskId ? tasksApi.retrieve(params.taskId) : undefined;
        let promise = Promise.all([request, taskReq]).then(([data, taskData]) => {
            this.entity(new Production(data, taskData, params.type || 'both'));
        });
        this.loadedAfter(promise);
    }

    save = () => {
        if (! this.entity()) {
            return;
        }

        if (this.validateLocal(this.entity)) {
            let data = this.entity().toData();
            this.executeSaveRequest(productionApi.save(data)).then((validation) => {
                this.onRemoteValidation(data, this.entity(), validation);
                setGlobalError(this.globalError, validation);
            });
        }
    }
}

export let productionEdit = { name: 'production-edit', viewModel: createWithComponent(ProductionEditScreen), template: template };

ko.components.register(productionEdit.name, productionEdit);
