import * as ko from 'knockout';

import i18n from 'main/i18n';
import { BarChartConfig } from '../ko_bindings/bar_chart';
import { NameData, NameI18nData, retrieve as retrieveName } from 'main/api/names';
import { getCropSearchConfig, getCropVarietySearchConfig, getRegionSearchConfig, getBuyerSearchConfig, getSeedTreatmentSearchConfig } from '../models/helpers/search_configs';
import { cropsApi, cropVarietyApi, SeedTreatmentData, seedTreatmentApi } from '../api/simple_api';
import { orgRegionApi } from 'main/api/org_regions';
import { table, chart, tableExport, DashboardChartData, DashboardTableData, DashboardParams, DashboardKPIData, kpi } from '../api/dashboards';
import { downloadBlob, extractId, extractIds, updateLocationWithQueryString, toDict, asArray, getDefault } from 'main/utils';
import { session } from 'main/session';
import { safeParseDate, serializeDate } from 'main/api/serialization';
import { FormSelectSearchConfiguration } from 'main/components/form_select_search';
import { CountryData, countriesApi } from 'main/api/countries';
import { getCropCategorySearchConfig, getCountrySearchConfig, getProjectSearchConfig, getProducerSearchConfig, getRegistrationRegionSearchConfig, getPhaseSearchConfig, getPISearchConfig } from 'main/components/configs/search_configs';
import { ProducerData, BuyerData, producersApi, buyersApi } from 'main/api/organizations';
import { projectApi } from 'main/api/projects';
import { PortofolioItemData, portofolioItemApi } from 'main/api/portofolio_items';
import { RegistrationRegionData, regRegionsApi } from 'main/api/registration_regions';
import { RegistrationPhaseData, registrationPhasesApi } from 'main/api/registration_phases';
import { ColumnSelectManager } from 'main/components/columns_select';
import { PartnerCropVarietyData } from 'main/api/crop_varieties';

let template = require('raw-loader!../../templates/dashboard.html').default;

type DashboardLinkParams = {
    [P in keyof DashboardParams]: string;
} & {
    selected: string;
}

interface ExtendedTableData extends DashboardTableData {
    columnSelectManager: ColumnSelectManager;
}

class DashboardScreen {
    private requestNumber = 0;
    private requestNumberKPI = 0;

    loadingFilters = ko.observable(true);
    loading = ko.observable(false);
    loadingKPI = ko.observable(false);
    exporting = ko.observable(false);
    selected = ko.observable<'chart' | 'table'>('chart');
    tableData = ko.observableArray<ExtendedTableData>();
    chartData = ko.observableArray<DashboardChartData>();
    kpiData = ko.observableArray<DashboardKPIData>();

    showForecasts = ko.observable(true);

    groupByProject = ko.observable(false);
    groupByCropCategory = ko.observable(false);
    groupByCrop = ko.observable(false);
    groupByCropVariety = ko.observable(false);
    groupByCountry = ko.observable(false);
    groupByRegion = ko.observable(false);
    groupByProducer = ko.observable(false);
    groupByBuyer = ko.observable(false);
    groupByBreeder = ko.observable(false);
    groupByYear = ko.observable(false);

    project = ko.observable<NameData>(null);
    cropCategory = ko.observable<NameI18nData>(null);
    crop = ko.observable<NameI18nData>(null);
    cropVariety = ko.observable<PartnerCropVarietyData>(null);
    seedTreatment = ko.observable<SeedTreatmentData>(null);
    country = ko.observable<CountryData>(null);
    region = ko.observable<NameData>(null);
    registrationRegion = ko.observable<RegistrationRegionData>(null);
    private registrationPhasesUnset = false;
    registrationPhases = ko.observableArray<RegistrationPhaseData>(null);
    producer = ko.observable<ProducerData>(null);
    buyer = ko.observable<BuyerData>(null);
    minDate = ko.observable<Date>(null);
    maxDate = ko.observable<Date>(null);
    portofolioItems = ko.observableArray<PortofolioItemData>(null);
    supportBySFSA = ko.observable<'yes' | 'no' | 'both'>('both');
    breeding = ko.observable<'public' | 'private' | 'any' | 'mixed'>('any');

    supportOptions = [
        { name: i18n.t('Yes')(), value: 'yes' },
        { name: i18n.t('No')(), value: 'no' },
        { name: i18n.t('Both')(), value: 'both' }
    ];
    breedingOptions = [
        { name: i18n.t('Public')(), value: 'public' },
        { name: i18n.t('Private')(), value: 'private' },
        { name: i18n.t('Mixed')(), value: 'mixed' },
        { name: i18n.t('Any')(), value: 'any' }
    ];
    projectSearchConfig: FormSelectSearchConfiguration<NameData>;
    cropCategorySearchConfig: FormSelectSearchConfiguration<NameI18nData>;
    cropSearchConfig: FormSelectSearchConfiguration<NameI18nData>;
    cropVarietySearchConfig: FormSelectSearchConfiguration<PartnerCropVarietyData>;
    seedTreatmentSearchConfig: FormSelectSearchConfiguration<NameData>;
    countrySearchConfig: FormSelectSearchConfiguration<NameData>;
    regionSearchConfig: FormSelectSearchConfiguration<NameData>;
    registrationRegionSearchConfig: FormSelectSearchConfiguration<NameI18nData>;
    registrationPhaseSearchConfig: FormSelectSearchConfiguration<NameI18nData>;
    producerSearchConfig: FormSelectSearchConfiguration<NameData>;
    buyerSearchConfig: FormSelectSearchConfiguration<NameData>;
    piSearchConfig: FormSelectSearchConfiguration<PortofolioItemData>;

    chartConfigs = ko.pureComputed<BarChartConfig[]>(() => {
        return this.chartData().map(data => {
            let res: BarChartConfig = {
                title: data.title,
                xTitle: data.x_title,
                fontSize: 12,
                labels: data.x_labels,
                datasets: data.data
            };

            return res;
        });
    });
    fullscreenChart = ko.observable<BarChartConfig>(null);
    downloadableChart = ko.observable<BarChartConfig>(null);

    private subscriptions: KnockoutSubscription[] = [];

    private tenantId: string;

    public: boolean;
    company = session.isCompany();

    constructor(params: { tenantId?: string, public: boolean, filters: DashboardLinkParams }) {
        this.tenantId = session.tenant() ? session.tenant().slug : params.tenantId;
        this.public = params.public || !session.authorized();

        this.projectSearchConfig = getProjectSearchConfig(this.project, { disableCreate: true, tenantId: this.tenantId });
        this.cropCategorySearchConfig = getCropCategorySearchConfig(this.cropCategory, { disableCreate: true, tenantId: this.tenantId, publicList: true });
        this.cropSearchConfig = getCropSearchConfig(this.crop, { tenantId: this.tenantId, publicList: true });
        this.cropVarietySearchConfig = getCropVarietySearchConfig(this.cropVariety, this.crop, { tenantId: this.tenantId });
        this.seedTreatmentSearchConfig = getSeedTreatmentSearchConfig(this.seedTreatment, this.crop, { disableCreate: true, tenantId: this.tenantId });
        this.countrySearchConfig = getCountrySearchConfig(this.country, { disableCreate: true, tenantId: this.tenantId, publicList: true });
        this.regionSearchConfig = getRegionSearchConfig(this.region, { disableCreate: true, tenantId: this.tenantId, publicList: true });
        this.registrationRegionSearchConfig = getRegistrationRegionSearchConfig(this.registrationRegion, { disableCreate: true, tenantId: this.tenantId, publicList: true });
        this.registrationPhaseSearchConfig = getPhaseSearchConfig(this.registrationPhases, { disableCreate: true, tenantId: this.tenantId });
        this.producerSearchConfig = getProducerSearchConfig(this.producer, { disableCreate: true, tenantId: this.tenantId });
        this.buyerSearchConfig = getBuyerSearchConfig(this.buyer, { disableCreate: true, tenantId: this.tenantId });
        this.piSearchConfig = getPISearchConfig(this.portofolioItems, { disableCreate: true, tenantId: this.tenantId });

        let filters = params.filters;
        this.selected(filters.selected === 'table' ? 'table' : 'chart');
        this.showForecasts(filters.show_forecasts !== undefined ? filters.show_forecasts === 'true' : true);
        this.groupByProject(filters.group_by_project === 'true');
        this.groupByCropCategory(filters.group_by_crop_category === 'true');
        this.groupByCrop(filters.group_by_crop === 'true');
        this.groupByCropVariety(filters.group_by_crop_variety === 'true');
        this.groupByCountry(filters.group_by_country === 'true');
        this.groupByRegion(filters.group_by_region === 'true');
        this.groupByProducer(filters.group_by_producer === 'true');
        this.groupByBuyer(filters.group_by_buyer === 'true');
        this.groupByBreeder(filters.group_by_breeder === 'true');
        this.groupByYear(filters.group_by_year === 'true');
        if (filters.min_date !== undefined) {
            this.minDate(safeParseDate(filters.min_date));
        }
        if (filters.max_date !== undefined) {
            this.maxDate(safeParseDate(filters.max_date));
        }
        if (filters.support_by_sfsa) {
            this.supportBySFSA(filters.support_by_sfsa === 'yes' ? 'yes' : (filters.support_by_sfsa === 'no' ? 'no' : 'both'));
        }
        if (filters.breeding) {
            this.breeding(filters.breeding === 'public' ? 'public' : (filters.breeding === 'private' ? 'private' : (filters.breeding === 'mixed' ? 'mixed' : 'any')));
        }

        let project = filters.project ? projectApi.retrieve(filters.project, { tenantId: this.tenantId }) : null;
        let cropCategory = filters.crop_category ? retrieveName('crop_categories', filters.crop_category, { tenantId: this.tenantId, publicList: true }) : null;
        let crop = filters.crop ? cropsApi.retrieve(filters.crop, { tenantId: this.tenantId, publicList: true }) : null;
        let cropVariety = filters.crop_variety ? cropVarietyApi.retrieve(filters.crop_variety, { tenantId: this.tenantId }) : null;
        let seedTreatment = filters.seed_treatment ? seedTreatmentApi.retrieve(filters.seed_treatment, { tenantId: this.tenantId }) : null;
        let country = filters.country ? countriesApi.retrieve(filters.country, { tenantId: this.tenantId, publicList: true }) : null;
        let region = filters.region ? orgRegionApi.retrieve(filters.region, { tenantId: this.tenantId, publicList: true }) : null;
        let registrationRegion = filters.registration_region ? regRegionsApi.retrieve(filters.registration_region, { tenantId: this.tenantId, publicList: true }) : null;
        let registrationPhases = Promise.resolve([]);
        this.registrationPhasesUnset = filters.registration_phases === 'unset';
        if(!this.registrationPhasesUnset && !this.public && !this.company) {
            const phaseIds = asArray(filters.registration_phases)
            registrationPhases = phaseIds.length > 0 ? registrationPhasesApi.list({ ids: phaseIds }, { tenantId: this.tenantId }) : registrationPhasesApi.list({ name_prefix: "Registration issued"});
        }
        let producer = filters.producer ? producersApi.retrieve(filters.producer, { tenantId: this.tenantId }) : null;
        let buyer = filters.buyer ? buyersApi.retrieve(filters.buyer, { tenantId: this.tenantId }) : null;
        let portofolioItems = filters.portofolio_items ? portofolioItemApi.list({ ids: asArray(filters.portofolio_items) }, { tenantId: this.tenantId }) : Promise.resolve<PortofolioItemData[]>([]);

        Promise.all([
            project,
            cropCategory,
            crop,
            cropVariety,
            seedTreatment,
            country,
            region,
            registrationRegion,
            registrationPhases as any,
            producer,
            buyer,
            portofolioItems,
        ]).then(([
            project,
            cropCategory,
            crop,
            cropVariety,
            seedTreatment,
            country,
            region,
            registrationRegion,
            registrationPhases,
            producer,
            buyer,
            portofolioItems
        ]) => {
            this.project(project);
            this.cropCategory(cropCategory);
            this.crop(crop);
            this.cropVariety(cropVariety as PartnerCropVarietyData);
            this.seedTreatment(seedTreatment);
            this.country(country);
            this.region(region);
            this.registrationRegion(registrationRegion as RegistrationRegionData);
            this.registrationPhases(registrationPhases || []);
            this.producer(producer);
            this.buyer(buyer);
            this.portofolioItems(portofolioItems);

            this.loadingFilters(false);

            // must run before subscriptions that change the URL
            this.subscriptions.push(this.registrationPhases.subscribe(value => {
                this.registrationPhasesUnset = value.length === 0;
            }));

            this.sub(this.selected);
            this.sub(this.showForecasts);
            this.sub(this.groupByProject);
            this.sub(this.groupByCropCategory);
            this.sub(this.groupByCrop);
            this.sub(this.groupByCropVariety);
            this.sub(this.groupByCountry);
            this.sub(this.groupByRegion);
            this.sub(this.groupByProducer);
            this.sub(this.groupByBuyer);
            this.sub(this.groupByBreeder);
            this.sub(this.groupByYear);
            this.sub(this.project);
            this.sub(this.cropCategory);
            this.sub(this.crop);
            this.sub(this.cropVariety);
            this.sub(this.seedTreatment);
            this.sub(this.portofolioItems);
            this.sub(this.country);
            this.sub(this.region);
            this.sub(this.registrationRegion);
            this.sub(this.registrationPhases);
            this.sub(this.producer);
            this.sub(this.buyer);
            this.sub(this.supportBySFSA);
            this.sub(this.breeding);
            this.sub(this.minDate);
            this.sub(this.maxDate);

            this.subKPI(this.groupByBuyer);
            this.subKPI(this.project);
            this.subKPI(this.cropCategory);
            this.subKPI(this.crop);
            this.subKPI(this.cropVariety);
            this.subKPI(this.seedTreatment);
            this.subKPI(this.portofolioItems);
            this.subKPI(this.country);
            this.subKPI(this.region);
            this.subKPI(this.producer);
            this.subKPI(this.buyer);
            this.subKPI(this.breeding);
            this.subKPI(this.minDate);
            this.subKPI(this.maxDate);

            this.onChange();
            this.onChangeKPI();
        });
    }

    private sub(obs: KnockoutObservable<{}>) {
        this.subscriptions.push(obs.subscribe(this.onChange));
    }

    private subKPI(obs: KnockoutObservable<{}>) {
        this.subscriptions.push(obs.subscribe(this.onChangeKPI));
    }

    dispose() {
        for (let sub of this.subscriptions) {
            sub.dispose();
        }
    }

    onChange = () => {
        let requestNumber = ++this.requestNumber;
        this.loading(true);

        updateLocationWithQueryString(this.toLinkData());

        if (this.selected() === 'table') {
            table(this.tenantId, this.toData()).then(data => {
                if (requestNumber !== this.requestNumber) {
                    return;
                }
                // preserve existing choices while reloading
                let shownColumns = toDict(this.tableData(), item => (
                    [item.title, toDict(item.columnSelectManager.columns, (column, index) => (
                        [column.title, item.columnSelectManager.isShown(index)]
                    ))]
                ));

                this.tableData(data.map(table => ({
                    columnSelectManager: new ColumnSelectManager(table.labels.map(label => ({
                        title: label.title,
                        initialValue: getDefault(shownColumns, label.initially_visible, table.title, label.title)
                    }))),
                    ...table
                })));
                this.loading(false);
            }).catch((e) => {
                console.log(e);
                this.loading(false);
            });
        } else {
            chart(this.tenantId, this.toData()).then(data => {
                if (requestNumber !== this.requestNumber) {
                    return;
                }

                this.chartData(data);
                this.loading(false);
            }).catch((e) => {
                console.log(e);
                this.loading(false);
            });
        }
    }

    onChangeKPI = () => {
        let requestNumberKPI = ++this.requestNumberKPI;
        this.loadingKPI(true);

        kpi(this.tenantId, this.toData()).then(data => {
            if (requestNumberKPI !== this.requestNumberKPI) {
                return;
            }

            this.kpiData(data);
            this.loadingKPI(false);
        }).catch((e) => {
            console.log(e);
            this.loadingKPI(false);
        });
    }

    selectChart = () => {
        this.selected('chart');
    }

    selectTable = () => {
        this.selected('table');
    }

    downloadTable = () => {
        this.exporting(true);

        tableExport(this.tenantId, this.toData()).then(data => {
            this.exporting(false);
            downloadBlob(data, 's2bim-export-' + serializeDate(new Date()) + '-.xlsx');
        }).catch(() => {
            this.exporting(false);
        });
    }

    showFullscreenChart = (chart: BarChartConfig) => {
        this.downloadableChart({ ...chart, fontSize: chart.fontSize * 2 });
        this.fullscreenChart(chart);
    }

    hideFullscreenChart = () => {
        this.fullscreenChart(null);
        this.downloadableChart(null);
    }

    downloadChart = () => {
        let canvas = <HTMLCanvasElement>document.getElementById('download-chart');
        let fileName = this.fullscreenChart().title + '.png';
        if ((canvas as any).msToBlob) {
            downloadBlob((canvas as any).msToBlob(), fileName);
        } else {
            canvas.toBlob(blob => {
                downloadBlob(blob, fileName);
            });
        }
    }

    private toLinkData() {
        let data: { [key: string]: {} } = { selected: this.selected(), ...this.toData() };

        for (let k in data) {
            if (k === 'show_forecasts') {
                if (data[k] === true) {
                    data[k] = undefined;
                }
                continue;
            }
            if (k === 'selected') {
                if (data[k] === 'chart') {
                    data[k] = undefined;
                }
                continue;
            }
            if (k === 'support_by_sfsa' || k === 'breeding') {
                if (data[k] === 'both' || data[k] === 'any') {
                    data[k] = undefined;
                }
                continue;
            }

            if (data[k] === false) {
                data[k] = undefined;
            }
        }

        if (this.registrationPhasesUnset) {
            data['registration_phases'] = 'unset';
        }

        return data;
    }

    private toData(): DashboardParams {
        return {
            show_forecasts: this.showForecasts(),
            group_by_project: this.groupByProject(),
            group_by_crop_category: this.groupByCropCategory(),
            group_by_crop: this.groupByCrop(),
            group_by_crop_variety: this.groupByCropVariety(),
            group_by_country: this.groupByCountry(),
            group_by_region: this.groupByRegion(),
            group_by_producer: this.groupByProducer(),
            group_by_buyer: this.groupByBuyer(),
            group_by_breeder: this.groupByBreeder(),
            group_by_year: this.groupByYear(),
            project: extractId(this.project),
            crop_category: extractId(this.cropCategory),
            crop: extractId(this.crop),
            crop_variety: extractId(this.cropVariety),
            seed_treatment: extractId(this.seedTreatment),
            country: extractId(this.country),
            region: extractId(this.region),
            registration_region: extractId(this.registrationRegion),
            registration_phases: extractIds(this.registrationPhases),
            producer: extractId(this.producer),
            buyer: extractId(this.buyer),
            min_date: serializeDate(this.minDate()),
            max_date: serializeDate(this.maxDate()),
            support_by_sfsa: this.supportBySFSA(),
            breeding: this.breeding(),
            portofolio_items: extractIds(this.portofolioItems),
        };
    }
}

export let dashboard = { name: 'dashboard', viewModel: DashboardScreen, template: template };

ko.components.register(dashboard.name, dashboard);
