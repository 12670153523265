import * as ko from 'knockout';

import i18n from 'main/i18n';
import { ListRequestParams } from 'main/api/request';
import { ListLoaderDelegate } from 'main/components/list_loader';
import { ProductionData, productionApi, SalesFiltersData } from '../api/simple_api';
import { Production } from '../models/production';
import { session } from 'main/session';
import { SalesFilters } from './sales';
import { updateLocationWithQueryString, downloadBlob } from 'main/utils';
import { translate } from 'main/i18n_text';
import { addChangelogListAction, addChangelogAction } from 'main/screens/changelog';
import { Action } from 'main/components/basic_widgets';

let template = require('raw-loader!../../templates/productions.html').default;

class ProductionsScreen implements ListLoaderDelegate<ProductionData, Production> {
    private productionsFilters = new SalesFilters({
        producerTitle: i18n.t('Producer')(),
        includeBuyer: false,
        includeSeason: true,
    });
    filters = this.productionsFilters.filters;
    loading = ko.observable(true);
    private exporting = ko.observable(false);

    isCompany = session.isCompany();
    canCreate = session.isAtLeastEditor();

    private onExport = async () => {
        this.exporting(true);
        try {
            let blob = await productionApi.exportList(this.productionsFilters.toData());
            downloadBlob(blob, 'productions.xlsx');
        } finally {
            this.exporting(false);
        }
    };

    listActions = addChangelogListAction('production', [
        {
            title: i18n.t('Export')(),
            icon: 'file_download',
            onClick: this.onExport,
            loading: this.exporting
        }
    ]);

    constructor(params: { filters: SalesFiltersData }) {
        this.productionsFilters.setInitial(params.filters).then(() => {
            this.loading(false);
        });
    }

    fetch(params: ListRequestParams) {
        let data = this.productionsFilters.toData();
        updateLocationWithQueryString(data);
        return productionApi.list({ ...data, ...params });
    }

    instantiate(data: ProductionData) {
        return new Production(data);
    }

    getName(entity: Production) {
        return entity.country().name + ', ' + translate(entity.cropVariety().name_json) + ', ' + entity.producer().name + ', ' + translate(entity.season().name_json) + '/' + entity.seasonYear();
    }

    getEditUrl(entity: Production) {
        return entity.editUrl;
    }

    remove(id: string) {
        return productionApi.remove(id);
    }

    canRemove(entity: Production) {
        return entity.canEdit();
    }

    getActions(entity: Production): Action[] {
        return addChangelogAction('production', entity.id());
    }
}

export let productions = { name: 'productions', viewModel: ProductionsScreen, template: template };

ko.components.register(productions.name, productions);
