import * as ko from 'knockout';

import { BaseForm } from 'main/screens/base_form';
import { Assumption } from '../models/assumption';
import { AssumptionData, assumptionApi, SaleData, saleApi } from '../api/simple_api';
import { Deferred } from 'main/utils/deferred';
import { createWithComponent, makeHasError } from 'main/utils/ko_utils';
import { ListLoaderDelegate } from 'main/components/list_loader';
import { ListRequestParams } from 'main/api/request';
import { extractId } from 'main/utils';
import { NameI18nData } from 'main/api/names';
import { CountryData } from 'main/api/countries';
import { app } from 'main/app';
import i18n from 'main/i18n';

let template = require('raw-loader!../../templates/assumption_edit.html').default;

class PreviousSales implements ListLoaderDelegate<SaleData, SaleData> {
    constructor(private country: KnockoutObservable<CountryData>, private crop: KnockoutObservable<NameI18nData>, private year: number) {
    }

    fetch(params: ListRequestParams) {
        return saleApi.list({
            country: extractId(this.country),
            crop: extractId(this.crop),
            crop_variety: undefined,
            producer: undefined,
            buyer: undefined,
            min_year: undefined,
            max_year: this.year,
            season: undefined,
            portofolio_item: undefined,
            exclude_forecasts: true,
            ...params
        });
    }

    instantiate(data: SaleData) {
        return data;
    }

    getName(entity: SaleData) {
        return '';
    }

    getEditUrl(entity: SaleData) {
        return '';
    }

    remove(id: string) {
        return saleApi.remove(id);
    }

    canRemove(entity: SaleData) {
        return false;
    }
}

class AssumptionEditScreen extends BaseForm<AssumptionData> {
    selected = ko.observable<'general' | 'farmer' | 'price' | 'profit' | 'national' | 'previous_sales'>('general');
    entity = ko.observable<Assumption>(null);
    previousSales: PreviousSales;

    hasGeneralError: KnockoutComputed<boolean>;
    hasFarmerError: KnockoutComputed<boolean>;
    hasPriceError: KnockoutComputed<boolean>;
    hasProfitError: KnockoutComputed<boolean>;
    hasNationalError: KnockoutComputed<boolean>;

    hasValidationError = ko.observable(false);
    private isPopupOpen = false;

    constructor(params: { id: string, readOnly?: boolean, result?: Deferred<AssumptionData> }, componentInfo: KnockoutComponentTypes.ComponentInfo) {
        super(params);

        let request = params.id ? assumptionApi.retrieveRecord(params.id, !!params.readOnly) : undefined;
        let promise = Promise.all([request]).then(([data]) => {
            let entity = new Assumption(!!params.readOnly, data);

            this.hasGeneralError = makeHasError(entity.country, entity.crop, entity.year);
            this.hasFarmerError = makeHasError(entity.seedPlantingDensity, entity.avgPlotSize);
            this.hasPriceError = makeHasError(
                entity.seedPrice,
                entity.cropPrice
            );
            this.hasProfitError = makeHasError(entity.grossProfit);
            let national = makeHasError(
                entity.annualCropAreaGrown,
                entity.seedProduction,
                entity.seedImports,
                entity.baselineYield,
                entity.yieldGain,
                entity.buyingFrequencies
            );
            this.hasNationalError = ko.pureComputed(() => {
                return national() || entity.hasSubServerError();
            });

            this.entity(entity);
        });
        promise.then(() => {
            let entity = this.entity();
            this.previousSales = new PreviousSales(entity.country, entity.crop, entity.existingYear);
        });
        this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    }

    selectGeneral = () => { this.selected('general'); }
    selectFarmer = () => { this.selected('farmer'); }
    selectPrice = () => { this.selected('price'); }
    selectProfit = () => { this.selected('profit'); }
    selectNational = () => { this.selected('national'); }
    selectPreviousSales = () => { this.selected('previous_sales'); }

    confirm = () => {
        if (this.isPopupOpen) {
            return;
        }

        this.hasValidationError(false);

        this.isPopupOpen = true;
        let result = new Deferred<{}>();
        result.promise.then(() => this.isPopupOpen = false).catch(() => this.isPopupOpen = false);
        app.formsStackController.push({
            title: i18n.t('Confirm')(),
            name: 'assumption-edit-confirm',
            params: { parentForm: this, result }
        });
    }

    save = () => {
        if (! this.entity()) {
            return;
        }

        this.hasValidationError(false);

        if (this.validateLocal(this.entity)) {
            let data = this.entity().toData();
            this.executeSaveRequest(assumptionApi.save(data)).then((validation) => {
                this.onRemoteValidation(data, this.entity(), validation);

                if (!validation.isValid) {
                    this.hasValidationError(true);

                    let bfErrors: any = validation.errors['buying_frequencies'];
                    if (bfErrors) {
                        for (let i = 0; i < bfErrors.length; i++) {
                            this.applyModelServerErrors(this.entity().buyingFrequencies()[i], bfErrors[i]);
                        }
                    }
                }
            });
        } else {
            this.hasValidationError(true);
        }
    }
}

export let assumptionEdit = { name: 'assumption-edit', viewModel: createWithComponent(AssumptionEditScreen), template: template };

ko.components.register(assumptionEdit.name, assumptionEdit);

let confirmTemplate = require('raw-loader!../../templates/assumption_edit_confirm.html').default;

class ConfirmPopup {
    parentForm: AssumptionEditScreen;
    result: Deferred<{}>;

    constructor(params: { parentForm: AssumptionEditScreen, result: Deferred<{}> }) {
        this.parentForm = params.parentForm;
        this.result = params.result;
    }

    onClose = () => {
        this.result.resolve(null);
    }
}

ko.components.register('assumption-edit-confirm', { viewModel: ConfirmPopup, template: confirmTemplate });
