window.APP_CONFIG = {
    TERMS_AND_CONDITIONS_URL: '',
    PRIVACY_POLICY_URL: '',
    PRIMARY_COLOR: '#7D943C',
    PRIMARY_COLOR_LIGHT: '#AFCB50',
    ACCENT_COLOR: '#502709',
    ACCENT_COLOR_LIGHT: '#B9A99D',
    CROP_VARIETY_REQUIRED_ORIGIN: true,
    SHOW_REGISTRATIONS: true,
    CAN_SEE_PRODUCERS: true,
    ALLOW_PASSWORD_LOGIN: false,
};
