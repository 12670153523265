import * as ko from 'knockout';
import { session } from '../session';
import { PortofolioItemData } from '../api/portofolio_items';
import { parseDate, serializeDate } from '../api/serialization';

export class PortofolioItem {
    id = ko.observable<string>(null);
    number = ko.observable<string>('').extend({ 
        required: true,
        pattern: {
            params: /^PI\d{5}$/,
            message: "Invalid value for ID",
        }
    });
    name = ko.observable<string>('').extend({ required: true });
    fromDate = ko.observable<Date>(null).extend({
        required: true,
        serverError: true,
    });
    toDate = ko.observable<Date>(null).extend({
        required: true
    });
    canEdit = ko.pureComputed(() => session.isAtLeastEditor());
    statusOptions = [
        {name: "Active", value: "Active"},
        {name: "Inactive", value: "Inactive"},
    ];
    status = ko.observable<string>(this.statusOptions[0].value).extend({
        required: true
    });

    editUrl: string;

    constructor(public data?: PortofolioItemData) {
        if (data) {
            this.id(data.id);
            this.number(data.number);
            this.name(data.name);
            this.fromDate(parseDate(data.from_date));
            this.toDate(parseDate(data.to_date));
            this.status(data.status);
        }

        this.editUrl = '/portofolio_items/' + this.id();
    }

    toData(): PortofolioItemData {
        return {
            id: this.id(),
            number: this.number(),
            name: this.name(),
            from_date: serializeDate(this.fromDate()),
            to_date: serializeDate(this.toDate()),
            status: this.status(),
        };
    }
}