import { request, requestRaw } from 'main/api/request';

export interface DashboardParams {
    show_forecasts: boolean;

    group_by_project: boolean;
    group_by_crop_category: boolean;
    group_by_crop: boolean;
    group_by_crop_variety: boolean;
    group_by_country: boolean;
    group_by_region: boolean;
    group_by_producer: boolean;
    group_by_buyer: boolean;
    group_by_breeder: boolean;
    group_by_year: boolean;

    project: string;
    crop_category: string;
    crop: string;
    crop_variety: string;
    seed_treatment: string;
    country: string;
    region: string;
    registration_region: string;
    registration_phases: string[];
    producer: string;
    buyer: string;
    min_date: string;
    max_date: string;
    portofolio_items: string[];
    support_by_sfsa: 'yes' | 'no' | 'both';
    breeding: 'public' | 'private' | 'mixed' | 'any';
}

export interface DashboardTableData {
    // index in rows is the index of the label
    title: string;
    labels: { title: string; numeric: boolean, initially_visible: boolean }[];
    rows: (string | number)[][];
}

export interface DashboardChartData {
    title: string;
    x_title: string;
    x_labels: string[];
    data: DashboardDatasets;
}

export type DashboardDatasets = {
    label: string;
    bar_type: 'fill' | 'outline';
    color_key: string;
    color_range: number;
    data: number[];
}[];

export interface DashboardKPIData {
    title: string;
    percentage: number;
    value: number;
    unit: string;
};

export function table(tenantId: string, params: DashboardParams): Promise<DashboardTableData[]> {
    return request('POST', '/api/t/' + tenantId + '/dashboards/table/', params, { injectTenant: false });
}

export function chart(tenantId: string, params: DashboardParams): Promise<DashboardChartData[]> {
    return request('POST', '/api/t/' + tenantId + '/dashboards/chart/', params, { injectTenant: false });
}

export function kpi(tenantId: string, params: DashboardParams): Promise<DashboardKPIData[]> {
    return request('POST', '/api/t/' + tenantId + '/dashboards/kpi/', params, { injectTenant: false });
}

export function tableExport(tenantId: string, params: DashboardParams): Promise<Blob> {
    return requestRaw('POST', '/api/t/' + tenantId + '/dashboards/table_export/', params, { injectTenant: false });
}
