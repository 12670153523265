import * as ko from 'knockout';

import i18n from 'main/i18n';
import { SaleData, TaskData, saleApi, ProductionSaleData } from '../api/simple_api';
import { FormSelectSearchConfiguration } from 'main/components/form_select_search';
import { readDecimal, emptyToNull } from 'main/utils';
import { session } from 'main/session';
import { getBuyerSearchConfig } from './helpers/search_configs';
import { CountryData } from 'main/api/countries';
import { getCountrySearchConfig, getProjectSearchConfig, getPISearchConfig } from 'main/components/configs/search_configs';
import { ProducerData, BuyerData, producersApi, OrganizationData } from 'main/api/organizations';
import { parseDateTime, parseDate, serializeDate } from 'main/api/serialization';
import { PartnerCropVarietyData } from 'main/api/crop_varieties';
import { CropData } from 'main/api/crops';
import { makeHasError } from 'main/utils/ko_utils';
import { Production } from './production';
import { ProjectData } from 'main/api/projects';
import { PortofolioItemData } from 'main/api/portofolio_items';

export class Sale {
    id = ko.observable<string>(null);
    country = ko.observable<CountryData>(null).extend({
        required: true
    });
    project = ko.observable<ProjectData>(null).extend({
        required: false
    });
    portofolioItem = ko.observable<PortofolioItemData>(null).extend({
        required: true
    });
    cropVariety = ko.observable<PartnerCropVarietyData>(null).extend({
        required: true
    });
    seller = ko.observable<ProducerData>(null).extend({
        required: true
    });
    buyer = ko.observable<BuyerData>(null);
    saleDate = ko.observable<Date>(null).extend({ required: true, serverError: true });
    quantityCertifiedSeedsActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    quantityEarlyGenActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    quantityCertifiedSeedsForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    quantityEarlyGenForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceCertifiedSeedsActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceCertifiedSeedsForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceEarlyGenActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceEarlyGenForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    yieldGain = ko.observable('').extend({
        number: true,
        serverError: true
    });
    totalSales = ko.observable(null).extend({
        number: true
    });
    comment = ko.observable('');
    private alreadyValidated = ko.observable(false);
    validated = ko.observable(false);
    validatedBy: string = null;
    validatedAt: Date = null;

    canEdit = ko.pureComputed(() => {
        return !this.id()
            || !this.country()
            || !this.seller()
            // NOTE: in case of companies/partners, user can only see editable sales to begin with
            || session.isCompany()
            || session.isPartner()
            || session.isAtLeastEditorFor(this.country());
    });
    canSelectCountry = ko.pureComputed(() => !this.taskData?.user && !(session.isCompany() && (session.tenant().producers.length + session.tenant().buyers.length) === 1));
    canSelectSeller = ko.pureComputed(() => !this.taskData?.user && !(session.isCompany() && session.tenant().producers.length === 1));
    canSelectBuyer = ko.pureComputed(() => !(session.isCompany() && session.tenant().buyers.length === 1));
    canValidate = ko.pureComputed(() => {
        return !this.alreadyValidated() && !session.isCompany() && this.canEdit();
    });

    projectSearchConfig = getProjectSearchConfig(this.project, {
        disableCreate: true,
        tenantId: session.tenant().slug,
    });
    piSearchConfig = getPISearchConfig(this.portofolioItem, {
        disableCreate: true,
        tenantId: session.tenant().slug,
    });

    editUrl: string;

    countrySearchConfig = getCountrySearchConfig(this.country);
    sellerSearchConfig: FormSelectSearchConfiguration<ProducerData> = {
        getSummaryName: (entity) => {
            return entity.name;
        },
        list: (params) => {
            return producersApi.list({ role: 'editor', ...params });
        },
        entity: this.seller
    };
    buyerSearchConfig = getBuyerSearchConfig(this.buyer);

    crop = ko.observable<CropData>(null);

    isCompany = session.isCompany();
    isProducer = session.isProducer();
    isBuyer = session.isBuyer();
    private forceForecastOnly = false;
    actualOnly = false;
    taskTitle = '';

    forecastOnly = ko.pureComputed(() => {
        let year = this.saleDate()?.getFullYear();
        return this.forceForecastOnly || (year && year > new Date().getFullYear());
    });

    formatLongNameOrEmail = (value: string) => {
        if (!value) {
            return "";
        }
        
        if (value.includes('@')) {
            const emailParts = value.split('@');
            return `${emailParts[0]}@` + '\n' + emailParts[1];
        }

        return value;
    };

    private subscriptions: KnockoutSubscription[] = [];

    constructor(public data?: SaleData, public taskData?: TaskData, type?: 'actual' | 'forecast' | 'both') {
        if (taskData) {
            this.forceForecastOnly = taskData.target === 'forecast';
            this.actualOnly = taskData.target === 'actual_data';

            if (this.forecastOnly()) {
                this.taskTitle = i18n.t('Sale forecast')();
                this.quantityCertifiedSeedsForecast = this.quantityCertifiedSeedsForecast.extend({ required: true });
                this.quantityEarlyGenForecast = this.quantityEarlyGenForecast.extend({ required: true });
                this.priceCertifiedSeedsForecast = this.priceCertifiedSeedsForecast.extend({ required: true });
                this.priceEarlyGenForecast = this.priceEarlyGenForecast.extend({ required: true });
            }
            if (this.actualOnly) {
                this.taskTitle = i18n.t('Actual Sale')();
                this.quantityCertifiedSeedsActual = this.quantityCertifiedSeedsActual.extend({ required: true });
                this.quantityEarlyGenActual = this.quantityEarlyGenActual.extend({ required: true });
                this.priceCertifiedSeedsActual = this.priceCertifiedSeedsActual.extend({ required: true });
                this.priceEarlyGenActual = this.priceEarlyGenActual.extend({ required: true });
            }
        } else {
            this.actualOnly = type === 'actual';
            this.forceForecastOnly = type === 'forecast';

            let producer = session.tenant().producers[0];
            let buyer = session.tenant().buyers[0];
            if (!this.canSelectCountry()) {
                this.country({ id: (producer ?? buyer).country_id, name: '', iso_country_code: '', regions: [], season_1: null, season_2: null, season_3: null, month_of_harvest_1: null, month_of_harvest_2: null, month_of_harvest_3: null, user_roles: [] });
            }
            if (!this.canSelectSeller()) {
                this.seller(asOrganizationData(producer.id));
            }
            if (!this.canSelectBuyer()) {
                this.buyer(asOrganizationData(buyer.id));
            }
        }

        if (data) {
            this.id(data.id);
            this.project(data.project);
            this.country(data.country);
            this.cropVariety(data.crop_variety);
            this.seller(data.seller);
            this.buyer(data.buyer);
            this.portofolioItem(data.portofolio_item);
            this.saleDate(parseDate(data.sale_date));
            this.quantityCertifiedSeedsActual(readDecimal(data.quantity_certified_seeds_actual));
            this.quantityEarlyGenActual(readDecimal(data.quantity_early_gen_actual));
            this.quantityCertifiedSeedsForecast(readDecimal(data.quantity_certified_seeds_forecast));
            this.quantityEarlyGenForecast(readDecimal(data.quantity_early_gen_forecast));
            this.priceCertifiedSeedsActual(readDecimal(data.price_certified_seeds_actual));
            this.priceEarlyGenActual(readDecimal(data.price_early_gen_actual));
            this.priceCertifiedSeedsForecast(readDecimal(data.price_certified_seeds_forecast));
            this.priceEarlyGenForecast(readDecimal(data.price_early_gen_forecast));
            this.yieldGain(readDecimal(data.yield_gain));
            this.comment(data.comment);
            this.totalSales(readDecimal(data.total_sales));

            if (data.validated_by) {
                this.alreadyValidated(true);
                this.validated(true);
                this.validatedBy = data.validated_by;
                this.validatedAt = parseDateTime(data.validated_at);
            }
        }

        if (taskData) {
            this.country((taskData.producer || taskData.buyer).country);
            this.cropVariety(taskData.crop_variety);
            if (taskData.producer) {
                this.seller(taskData.producer);
            }
            if (taskData.buyer) {
                this.buyer(taskData.buyer);
            }
        }

        if (session.isAtLeastEditor() || session.isCompany()) {
            this.sellerSearchConfig.create = {
                title: i18n.t('Producer')(),
                componentName: 'producer-edit'
            };
        }

        this.editUrl = '/sales/' + this.id();

        if (this.cropVariety()) {
            this.crop(this.cropVariety().crop_id);
        }
    }

    dispose() {
        this.subscriptions.forEach(sub => sub.dispose());
    }

    toData(): SaleData {
        return {
            id: this.id(),
            country: this.country(),
            crop_variety: this.cropVariety(),
            seller: this.seller(),
            buyer: this.buyer(),
            sale_date: serializeDate(this.saleDate()),
            quantity_certified_seeds_actual: emptyToNull(this.quantityCertifiedSeedsActual()),
            quantity_early_gen_actual: emptyToNull(this.quantityEarlyGenActual()),
            quantity_certified_seeds_forecast: emptyToNull(this.quantityCertifiedSeedsForecast()),
            quantity_early_gen_forecast: emptyToNull(this.quantityEarlyGenForecast()),
            project: this.project(),
            price_certified_seeds_actual: emptyToNull(this.priceCertifiedSeedsActual()),
            price_early_gen_actual: emptyToNull(this.priceEarlyGenActual()),
            price_early_gen_forecast: emptyToNull(this.priceEarlyGenActual()),
            price_certified_seeds_forecast: emptyToNull(this.priceCertifiedSeedsForecast()),
            yield_gain: emptyToNull(this.yieldGain()),
            comment: this.comment(),
            portofolio_item: this.portofolioItem(),
            task_id: this.taskData ? this.taskData.id : null,
            validated: this.validated()
        };
    }

    saveRequest() {
        return saleApi.save(this.toData());
    }
}

function isQtValid(prod: Production, prodQtEarly: ko.Observable<string>, prodQt: ko.Observable<string>, getSaleQts: (sale: ProductionSale) => ko.Observable<string>[]) {
    let earlyQt = parseFloat(prodQtEarly());
    if (isNaN(earlyQt)) {
        earlyQt = 0;
    }
    let qt = parseFloat(prodQt());
    if (isNaN(qt)) {
        qt = 0;
    }

    let totalSold = 0;
    for (let sale of prod.sales()) {
        let [qtCertifiedSeeds, qtEarlyGen] = getSaleQts(sale);
        let saleQtCs = parseFloat(qtCertifiedSeeds());
        let saleQtEs = parseFloat(qtEarlyGen());
        if (!isNaN(saleQtCs) && !isNaN(saleQtEs)) {
            totalSold += (saleQtCs || 0) + (saleQtEs || 0);
        }
    }

    return totalSold <= qt + earlyQt;
}

export class ProductionSale {
    id = ko.observable<string>(null);
    buyer = ko.observable<BuyerData>(null);
    saleDate = ko.observable<Date>(null).extend({ required: true });
    quantityCertifiedSeedsActual: ko.Observable<string> = ko.observable(null).extend({
        number: true,
        serverError: true,
        validation: {
            validator: () => {
                return isQtValid(this.production, this.production.quantityEarlyGen, this.production.quantity, sale => [sale.quantityCertifiedSeedsActual, sale.quantityEarlyGenActual]);
            },
            message: i18n.t('Cannot exceed the produced quantity')()
        }
    });
    quantityCertifiedSeedsForecast: ko.Observable<string> = ko.observable(null).extend({
        number: true,
        serverError: true,
        validation: {
            validator: () => {
                return isQtValid(this.production, this.production.quantityEarlyGenForecast, this.production.quantityForecast, sale => [sale.quantityCertifiedSeedsForecast, sale.quantityEarlyGenForecast]);
            },
            message: i18n.t('Cannot exceed the produced quantity')()
        }
    });
    showEarlyGenActual = ko.observable(false);
    showEarlyGenForecast = ko.observable(false);
    priceCertifiedSeedsActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceCertifiedSeedsForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceEarlyGenActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    priceEarlyGenForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    quantityEarlyGenForecast = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    quantityEarlyGenActual = ko.observable(null).extend({
        number: true,
        serverError: true
    });
    yieldGain = ko.observable('').extend({
        number: true,
        serverError: true
    });
    comment = ko.observable('');
    private alreadyValidated = ko.observable(false);
    validated = ko.observable(false);
    validatedBy: string = null;
    validatedAt: Date = null;

    canValidate = ko.pureComputed(() => {
        return !this.alreadyValidated() && !session.isCompany();
    });
    canSelectBuyer = ko.pureComputed(() => !(session.isCompany() && session.tenant().buyers.length === 1));

    buyerSearchConfig = getBuyerSearchConfig(this.buyer);

    name = ko.pureComputed(() => i18n.t('Sale to')() + ' ' + (this.buyer() ? this.buyer().name : '—'));

    hasError = makeHasError(
        this.buyer, this.saleDate, this.quantityCertifiedSeedsActual, this.quantityCertifiedSeedsForecast,
        this.priceCertifiedSeedsActual, this.priceEarlyGenActual, this.priceCertifiedSeedsForecast, this.priceEarlyGenForecast,
        this.yieldGain, this.comment, this.quantityEarlyGenActual, this.quantityEarlyGenForecast
    );

    constructor(private production: Production, public data?: ProductionSaleData) {
        if (!this.canSelectBuyer()) {
            this.buyer(asOrganizationData(session.tenant().buyers[0].id));
        }

        if (data) {
            this.id(data.id);
            this.buyer(data.buyer);
            this.saleDate(parseDate(data.sale_date));
            this.quantityCertifiedSeedsActual(readDecimal(data.quantity_certified_seeds_actual));
            this.quantityCertifiedSeedsForecast(readDecimal(data.quantity_certified_seeds_forecast));
            this.quantityEarlyGenActual(readDecimal(data.quantity_early_gen_actual));
            this.quantityEarlyGenForecast(readDecimal(data.quantity_early_gen_forecast));
            this.priceCertifiedSeedsActual(readDecimal(data.price_certified_seeds_actual));
            this.priceEarlyGenActual(readDecimal(data.price_early_gen_actual));
            this.priceCertifiedSeedsForecast(readDecimal(data.price_certified_seeds_forecast));
            this.priceEarlyGenForecast(readDecimal(data.price_early_gen_forecast));
            this.yieldGain(readDecimal(data.yield_gain));
            this.comment(data.comment);

            if (this.quantityEarlyGenActual() !== '') {
                this.showEarlyGenActual(true);
            }

            if (this.quantityEarlyGenForecast() !== '') {
                this.showEarlyGenForecast(true);
            }

            if (data.validated_by) {
                this.alreadyValidated(true);
                this.validated(true);
                this.validatedBy = data.validated_by;
                this.validatedAt = parseDateTime(data.validated_at);
            }
        }
    }

    toggleShowEarlyGenActual = () => {
        this.showEarlyGenActual(true);
    }

    toggleShowEarlyGenForecast = () => {
        this.showEarlyGenForecast(true);
    }

    toData(idx: number): ProductionSaleData {
        return {
            id: this.id(),
            buyer: this.buyer(),
            sale_date: serializeDate(this.saleDate()),
            quantity_certified_seeds_actual: emptyToNull(this.quantityCertifiedSeedsActual()),
            quantity_early_gen_actual: emptyToNull(this.quantityEarlyGenActual()),
            quantity_certified_seeds_forecast: emptyToNull(this.quantityCertifiedSeedsForecast()),
            quantity_early_gen_forecast: emptyToNull(this.quantityEarlyGenForecast()),
            price_certified_seeds_actual: emptyToNull(this.priceCertifiedSeedsActual()),
            price_early_gen_actual: emptyToNull(this.priceEarlyGenActual()),
            price_certified_seeds_forecast: emptyToNull(this.priceCertifiedSeedsForecast()),
            price_early_gen_forecast: emptyToNull(this.priceEarlyGenForecast()),
            yield_gain: emptyToNull(this.yieldGain()),
            comment: this.comment(),
            validated: this.validated(),
            order_in_production: idx
        };
    }
}

function asOrganizationData(id: string): OrganizationData {
    return {
        id,
        name: '',
        country: null,
        contact: null,
        address: null,
        phone: null,
        email: null,
        responsible: null,
        organization_type: null,
        users_emails: null
    };
}
