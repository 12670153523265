import { DashboardKPIData } from '../api/dashboards';
import { cropsApi, cropVarietyApi, CropData } from '../api/simple_api';
import * as ko from 'knockout';
import { countriesApi, CountryData } from 'main/api/countries';
import { PartnerCropVarietyData } from 'main/api/crop_varieties';
import { NameData, NameI18nData, retrieve as retrieveName } from 'main/api/names';
import { orgRegionApi } from 'main/api/org_regions';
import { projectApi } from 'main/api/projects';
import { deflateList, safeParseDate, serializeDate } from 'main/api/serialization';
import {
  getProjectSearchConfig,
  getCropCategorySearchConfig,
  getCountrySearchConfig,
  getPISearchConfig,
} from 'main/components/configs/search_configs';
import { FormSelectSearchConfiguration } from 'main/components/form_select_search';
import { session } from 'main/session';
import { asArray, extractId, updateLocationWithQueryString } from 'main/utils';
import {
  getCropVarietySearchConfigForMultipleCrops,
  getCropSearchConfigCropData,
  getRegionSearchConfig,
} from '../models/helpers/search_configs';
import i18n from 'main/i18n';
import {
  DashboardOverviewData,
  DashboardTableData,
  DashboardV2Params,
  overviews,
  tables,
  tablesExport,
  DashboardTableSource,
  DashboardTableSourceEnum,
} from '../api/dashboards_v2';
import { downloadBlob } from 'main/utils';
import { BarChartConfig } from 'ko_bindings/bar_chart';
import { ChartConfiguration, Chart } from 'chart.js';
import { PortofolioItemData, portofolioItemApi } from 'main/api/portofolio_items';
import { debounce } from 'lodash';

let template = require('raw-loader!../../templates/dashboard_v2.html').default;

const BARCHART_TITLE = "BarChart";

export interface DashboardLinkParams {
  project: string;
  crop_category: string;
  crops: string[];
  crop_variety: string;
  countries: string[];
  region: string;
  min_date: string;
  max_date: string;
  portofolio_items: string[];
  display_only_validated_data: string;
}

type DisplayMode = 'overview' | 'table';

class DashboardV2Screen {
  private debouncedFetchOverviewsData = debounce(() => {
    overviews(this.tenantId, this.toData(), this.public)
    .then((data) => {
      this.loading(false);
      this.overviewsData(data);
      this.updateCharts();
    })
    .catch((e) => {
      console.log(e);
      this.loading(false);
    });
  }, 500);

  loadingFilters = ko.observable(true);
  loading = ko.observable(false);
  loadingKPI = ko.observable(false);
  loadingProductionsTable = ko.observable(false);
  loadingSalesTable = ko.observable(false);
  loadingDiffusionAndImpactTable = ko.observable(false);
  loadingRegistrationAndLicensingTable = ko.observable(false);
  loadingDiffusionAndImpactSuiteTable = ko.observable(false);
  barChart = ko.observable<Chart>();
  kpiData = ko.observableArray<DashboardKPIData>();

  overviewsData = ko.observable<DashboardOverviewData>();
  displayOnlyValidatedData = ko.observable('false');
  seedsProductionsTablesData = ko.observable<DashboardTableData[]>();
  seedsSalesTablesData = ko.observable<DashboardTableData[]>();
  diffusionAndImpactTablesData = ko.observable<DashboardTableData[]>();
  diffusionAndImpactSuiteTablesData = ko.observable<DashboardTableData[]>();
  registrationAndLicensingTablesData = ko.observable<DashboardTableData[]>();
  dataDisplayOptions = [
    { name: i18n.t('All data displayed')(), value: 'false' },
    { name: i18n.t('Only validated data displayed')(), value: 'true' },
  ];

  exportingProductionsTables = ko.observable(false);
  exportingSalesTables = ko.observable(false);
  exportingDiffusionAndImpactTables = ko.observable(false);
  exportingRegistrationAndLicensingTables = ko.observable(false);
  exportingDiffusionAndImpactSuiteTables = ko.observable(false);
  diffusionAndImpactSuiteChartConfig = ko.observable(null);
  diffusionAndImpactSuiteDataTotalFarmersLabel = ko.observable('');
  farmersPieChart = ko.observable(null);

  seedsProduced = ko.pureComputed(
    () =>
      `${this.overviewsData()?.seed_productions?.total_produced.toLocaleString([], {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) || 0} ${i18n.t('Tons')()}`
  );
  seedsForecasted = ko.pureComputed(
    () =>
      `${this.overviewsData()?.seed_productions?.total_forecasted.toLocaleString([], {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || 0} ${i18n.t('Tons')()}`
  );
  egsProduced = ko.pureComputed(
    () => `${this.overviewsData()?.seed_productions?.egs_produced.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) || 0} ${i18n.t('Tons')()}`
  );
  egsForecasted = ko.pureComputed(
    () =>
      `${this.overviewsData()?.seed_productions?.egs_forecasted.toLocaleString([], {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || 0} ${i18n.t('Tons')()}`
  );
  certifiedProduced = ko.pureComputed(
    () =>
      `${this.overviewsData()?.seed_productions?.certified_produced.toLocaleString([], {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || 0} ${i18n.t(
        'Tons'
      )()}`
  );
  certifiedForecasted = ko.pureComputed(
    () =>
      `${this.overviewsData()?.seed_productions?.certified_forecasted.toLocaleString([], {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || 0} ${i18n.t(
        'Tons'
      )()}`
  );

  seedsSold = ko.pureComputed(
    () => `${this.overviewsData()?.seed_sales.total_quantity.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) || 0} ${i18n.t('Tons')()}`
  );
  seedsTotalPrice = ko.pureComputed(
    () => `${this.overviewsData()?.seed_sales.total_price.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) || 0} ${i18n.t('USD')()}`
  );
  certifiedSeedsSold = ko.pureComputed(
    () => `${this.overviewsData()?.seed_sales.total_quantity_cs.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) || 0} ${i18n.t('Tons')()}`
  );
  certifiedSeedsTotalPrice = ko.pureComputed(
    () => `${this.overviewsData()?.seed_sales.total_price_cs.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) || 0} ${i18n.t('USD')()}`
  );
  earlyGenSeedsSold = ko.pureComputed(
    () => `${this.overviewsData()?.seed_sales.total_quantity_egs.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) || 0} ${i18n.t('Tons')()}`
  );
  earlyGenSeedsTotalPrice = ko.pureComputed(
    () => `${this.overviewsData()?.seed_sales.total_price_egs.toLocaleString([], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) || 0} ${i18n.t('USD')()}`
  );

  diffusionAndImpactData = ko.pureComputed(() => {
    const data = this.overviewsData()?.diffusion_of_new_varieties;
    if (!data) {
      return null;
    }
    const landWithEgs =
      data.area_extrapolated_from_sales.total_extrapolated_egs +
      data.area_under_seed_production.total_area_egs;
    const forecastedLandWithEgs =
      data.area_extrapolated_from_sales.total_extrapolated_egs_forecast +
      data.area_under_seed_production.total_area_egs_forecast;
    const landWithCertified =
      data.area_extrapolated_from_sales.total_extrapolated_certified +
      data.area_under_seed_production.total_area_certified;
    const forecastedLandWithCertified =
      data.area_extrapolated_from_sales.total_extrapolated_certified_forecast +
      data.area_under_seed_production.total_area_certified_forecast;
    const totalLand = landWithEgs + landWithCertified;
    const totalLandForecasted = forecastedLandWithEgs + forecastedLandWithCertified;

    const chartConfig: BarChartConfig = {
      fontSize: 12,
      labels: ['EGS', 'Certified seeds'],
      horizontal: true,
      datasets: [
        {
          label: i18n.t('Hectares under seed production')(),
          bar_type: 'fill',
          color: '#6581A8',
          color_key: '1',
          color_range: 1,
          data: [
            data.area_under_seed_production.total_area_egs,
            data.area_under_seed_production.total_area_certified,
          ],
          stack: '1',
        },
        {
          label: i18n.t('Hectares extrapolated from seed quantity sold')(),
          bar_type: 'fill',
          color: '#D3BB3C',
          color_key: '2',
          color_range: 2,
          data: [
            data.area_extrapolated_from_sales.total_extrapolated_egs,
            data.area_extrapolated_from_sales.total_extrapolated_certified,
          ],
          stack: '1',
        },
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            text: BARCHART_TITLE,
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: { display: false },
          },
          y: {
            stacked: true,
            grid: { display: false },
          },
        },
      },
    };

    const hectares = i18n.t('Hectares')();
    const ha = i18n.t('Ha')();
    return {
      totalLand: `${totalLand.toLocaleString([], { maximumFractionDigits: 2 })} ${hectares}`,
      totalLandForecasted: `${totalLandForecasted.toLocaleString([], {
        maximumFractionDigits: 2,
      })} ${hectares}`,
      landWithEgs: `${landWithEgs.toLocaleString([], { maximumFractionDigits: 2 })} ${ha}`,
      forecastedLandWithEgs: `${forecastedLandWithEgs.toLocaleString([], {
        maximumFractionDigits: 2,
      })} ${ha}`,
      landWithCertified: `${landWithCertified.toLocaleString([], { maximumFractionDigits: 2 })} ${ha}`,
      forecastedLandWithCertified: `${forecastedLandWithCertified.toLocaleString([], {
        maximumFractionDigits: 2,
      })} ${ha}`,
      smallholderLand: `${data.area_extrapolated_from_sales.total_extrapolated_certified.toLocaleString()} ${hectares}`,
      smallholderLandForecasted: `${data.area_extrapolated_from_sales.total_extrapolated_certified_forecast.toLocaleString()} ${hectares}`,
      chartConfig,
    };
  });

  checkIfDiffusionAndImpactSuiteDataIsGreaterThanZero = ko.computed(() => {
    const data = this.overviewsData()?.diffusion_of_new_varieties_suite;
    if (!data) {
      return false;
    }
    return data.male_farmers > 0 || data.female_farmers > 0;
  });

  registrationAndLicensingData = ko.pureComputed(() => {
    const data = this.overviewsData()?.variety_registration_and_licensing;
    if (!data) {
      return null;
    }
    const chartData: BarChartConfig = {
      title: i18n.t('Number of licenses')(),
      xTitle: i18n.t('Year')(),
      fontSize: 12,
      labels: data.licenses_count_by_year.map((licenses) => licenses.year.toString()),
      datasets: [
        {
          bar_type: 'fill',
          color_key: '1',
          color_range: 0,
          data: data.licenses_count_by_year.map((licenses) => licenses.count),
          label: '',
        },
      ],
    };
    return {
      national: `${data.national} ${i18n.t('varieties')()}`,
      regional: `${data.regional} ${i18n.t('varieties')()}`,
      chartData,
    };
  });

  project = ko.observable<NameData>(null);
  cropCategory = ko.observable<NameI18nData>(null);
  crops = ko.observableArray<CropData>(null);
  cropVariety = ko.observable<PartnerCropVarietyData>(null);

  countries = ko.observableArray<CountryData>(null);
  portofolioItems = ko.observableArray<PortofolioItemData>(null);
  region = ko.observable<NameData>(null);
  minDate = ko.observable<Date>(null);
  maxDate = ko.observable<Date>(null);

  projectSearchConfig: FormSelectSearchConfiguration<NameData>;
  cropCategorySearchConfig: FormSelectSearchConfiguration<NameI18nData>;
  cropSearchConfig: FormSelectSearchConfiguration<NameI18nData>;
  cropVarietySearchConfig: FormSelectSearchConfiguration<PartnerCropVarietyData>;
  countrySearchConfig: FormSelectSearchConfiguration<NameData>;
  regionSearchConfig: FormSelectSearchConfiguration<NameData>;
  piSearchConfig: FormSelectSearchConfiguration<PortofolioItemData>;

  private subscriptions: KnockoutSubscription[] = [];

  private tenantId: string;

  public: boolean;
  company = session.isCompany();

  seedProductionDisplayMode = ko.observable<DisplayMode>('overview');
  seedSalesDisplayMode = ko.observable<DisplayMode>('overview');
  diffusionAndImpactDisplayMode = ko.observable<DisplayMode>('overview');
  diffusionAndImpactSuiteDisplayMode = ko.observable<DisplayMode>('overview');
  registrationAndLicensingDisplayMode = ko.observable<DisplayMode>('overview');

  constructor(params: { tenantId?: string; public: boolean; filters: DashboardLinkParams }) {
    this.tenantId = session.tenant() ? session.tenant().slug : params.tenantId;
    this.public = params.public || !session.authorized();

    this.projectSearchConfig = getProjectSearchConfig(this.project, {
      disableCreate: true,
      tenantId: this.tenantId,
    });
    this.cropCategorySearchConfig = getCropCategorySearchConfig(this.cropCategory, {
      disableCreate: true,
      tenantId: this.tenantId,
      publicList: true,
    });
    this.cropSearchConfig = getCropSearchConfigCropData(this.crops, {
      tenantId: this.tenantId,
      publicList: true,
      disableCreate: false,
    });
    this.cropVarietySearchConfig = getCropVarietySearchConfigForMultipleCrops(
      this.cropVariety,
      this.crops().map((crop) => crop.id),
      {
        tenantId: this.tenantId,
      }
    );
    this.countrySearchConfig = getCountrySearchConfig(this.countries, {
      disableCreate: true,
      tenantId: this.tenantId,
      publicList: true,
    });
    this.piSearchConfig = getPISearchConfig(this.portofolioItems, {
      disableCreate: true,
      tenantId: this.tenantId,
      publicList: true,
    });
    this.regionSearchConfig = getRegionSearchConfig(this.region, {
      disableCreate: true,
      tenantId: this.tenantId,
      publicList: true,
    });

    let filters = params.filters;
    if (filters.min_date !== undefined) {
      this.minDate(safeParseDate(filters.min_date));
    }
    if (filters.max_date !== undefined) {
      this.maxDate(safeParseDate(filters.max_date));
    }

    this.displayOnlyValidatedData(filters.display_only_validated_data === 'true' ? 'true' : 'false');

    let project = filters.project ? projectApi.retrieve(filters.project, { tenantId: this.tenantId }) : null;
    let cropCategory = filters.crop_category
      ? retrieveName('crop_categories', filters.crop_category, { tenantId: this.tenantId, publicList: true })
      : null;
    let crops = filters.crops
      ? cropsApi.list({ ids: asArray(filters.crops) }, { tenantId: this.tenantId })
      : Promise.resolve<CropData[]>([]);
    let cropVariety = filters.crop_variety
      ? cropVarietyApi.retrieve(filters.crop_variety, { tenantId: this.tenantId })
      : null;

    let countries = filters.countries
      ? countriesApi.list({ ids: asArray(filters.countries) }, { tenantId: this.tenantId })
      : Promise.resolve<CountryData[]>([]);
    let portofolioItems = filters.portofolio_items
      ? portofolioItemApi.list({ ids: asArray(filters.portofolio_items) }, { tenantId: this.tenantId })
      : Promise.resolve<PortofolioItemData[]>([]);
    let region = filters.region
      ? orgRegionApi.retrieve(filters.region, { tenantId: this.tenantId, publicList: true })
      : null;

    Promise.all([project, cropCategory, crops, cropVariety, countries, region, portofolioItems]).then(
      ([project, cropCategory, crops, cropVariety, countries, region, portofolioItems]) => {
        this.project(project);
        this.cropCategory(cropCategory);
        this.crops(crops);
        this.cropVariety(cropVariety as PartnerCropVarietyData);
        this.countries(countries);
        this.portofolioItems(portofolioItems);
        this.region(region);

        this.loadingFilters(false);

        // must run before subscriptions that change the URL

        this.sub(this.project);
        this.sub(this.cropCategory);
        this.sub(this.crops);
        this.sub(this.cropVariety);
        this.sub(this.countries);
        this.sub(this.portofolioItems);
        this.sub(this.region);
        this.sub(this.minDate);
        this.sub(this.maxDate);
        this.sub(this.displayOnlyValidatedData);

        this.subTables(this.project);
        this.subTables(this.cropCategory);
        this.subTables(this.crops);
        this.subTables(this.cropVariety);
        this.subTables(this.countries);
        this.subTables(this.portofolioItems);
        this.subTables(this.region);
        this.subTables(this.minDate);
        this.subTables(this.maxDate);
        this.subTables(this.seedProductionDisplayMode);
        this.subTables(this.seedSalesDisplayMode);
        this.subTables(this.diffusionAndImpactDisplayMode);
        this.subTables(this.diffusionAndImpactSuiteDisplayMode);
        this.subTables(this.registrationAndLicensingDisplayMode);
        this.subTables(this.displayOnlyValidatedData);

        this.onChange();
      }
    );
  }

  private sub(obs: KnockoutObservable<{}>) {
    this.subscriptions.push(obs.subscribe(this.onChange));
  }

  private subTables(obs: KnockoutObservable<{}>) {
    this.subscriptions.push(obs.subscribe(this.onChangeTable));
  }

  dispose() {
    for (let sub of this.subscriptions) {
      sub.dispose();
    }
  }

  setExcelExportingBySource(source: DashboardTableSource, value: boolean) {
    switch (source) {
      case DashboardTableSourceEnum.PRODUCTIONS:
        this.exportingProductionsTables(value);
        break;
      case DashboardTableSourceEnum.SALES:
        this.exportingSalesTables(value);
        break;
      case DashboardTableSourceEnum.DIFFUSION_AND_IMPACT:
        this.exportingDiffusionAndImpactTables(value);
        break;
      case DashboardTableSourceEnum.REGISTRATION_AND_LICENSING:
        this.exportingRegistrationAndLicensingTables(value);
        break;
      case DashboardTableSourceEnum.DIFFUSION_AND_IMPACT_SUITE:
        this.exportingDiffusionAndImpactSuiteTables(value);
        break;
    }
  }

  processTables(tables: DashboardTableData[]) {
    return tables.map((table: DashboardTableData) => ({
      ...table,
      rows: table.rows.map((row: (string | number)[]) => this.processRow(row)),
    }));
  }

  processRow(rowData: (string | number)[]) {
    return rowData.map((cellData: string | number) => {
      if (typeof cellData === 'number') {
        return cellData.toLocaleString([], { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      }
      return !isNaN(Number(cellData))
        ? Number(cellData).toLocaleString([], {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        : cellData;
    });
  }

  downloadTables = (source: DashboardTableSource) => {
    this.setExcelExportingBySource(source, true);
    tablesExport(this.tenantId, this.toData(), source)
      .then((data) => {
        this.setExcelExportingBySource(source, false);
        downloadBlob(data, `s2bim-export-${source}-${serializeDate(new Date())}-.xlsx`);
      })
      .catch((err) => {
        this.setExcelExportingBySource(source, false);
      });
  };

  onChange = () => {
    this.loading(true);

    updateLocationWithQueryString(this.toLinkData());
    this.debouncedFetchOverviewsData();
  };

  updateDiffusionAndImpactFarmersChart = () => {
    const data = this.overviewsData()?.diffusion_of_new_varieties_suite;

    const { male_farmers, female_farmers } = data || {};

    const numberOfMaleFarmers = male_farmers;
    const numberOfFemaleFarmers = female_farmers;
    const totalNumberOfFarmers = numberOfFemaleFarmers + numberOfMaleFarmers;
    const translatedMaleFarmersTxt = i18n.t('Male farmers')();
    const translatedFemaleFarmersTxt = i18n.t('Female farmers')();

    const translatedMaleLabel = `${translatedMaleFarmersTxt} ${Math.round((
      (numberOfMaleFarmers / totalNumberOfFarmers) *
      100
    ))}% (${numberOfMaleFarmers.toLocaleString()})`;
    const translatedFemaleLabel = `${translatedFemaleFarmersTxt} ${Math.round((
      (numberOfFemaleFarmers / totalNumberOfFarmers) *
      100
    ))}% (${numberOfFemaleFarmers.toLocaleString()})`;
    const labels = [translatedMaleLabel, translatedFemaleLabel];
    const farmerNumbers = [numberOfMaleFarmers, numberOfFemaleFarmers];

    if (this.farmersPieChart()) {
      this.farmersPieChart().destroy();
      this.farmersPieChart(null);
    }

    const plugins = [
      {
        id: 'custom_canvas_change_background_color',
        beforeDraw: (chart: any, args: any, options: any) => {
          const { ctx } = chart;
          ctx.fillStyle = '#ece9e7';
          ctx.fillRect(0, 0, chart.width, chart.height);
        },
      },
      {
        id: 'update_tooltip_text',
        beforeDraw: (chart: any, args: any, options: any) => {
          if (!this.farmersPieChart()) {
            this.farmersPieChart(chart);
          }
          chart.config.data.labels = labels;
        },
      },
    ];

    const chartConfig: ChartConfiguration = {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{ data: farmerNumbers, backgroundColor: ['#A9B6C7', '#C98388'] }],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: 20,
        },
        plugins: {
          legend: {
            position: 'bottom',
            reverse: true,
            labels: {
              font: {size: 16},
            }
          },
        }
      },
      plugins: [...plugins],
    };

    this.diffusionAndImpactSuiteDataTotalFarmersLabel(
      `${totalNumberOfFarmers.toLocaleString()} ${i18n.t('farmers')()}`
    );
    this.diffusionAndImpactSuiteChartConfig(chartConfig);
  };

  updateCharts = () => {
    this.updateDiffusionAndImpactFarmersChart();
  };

  onChangeTable = () => {
    updateLocationWithQueryString(this.toLinkData());
    if (this.seedProductionDisplayMode() === 'table') {
      this.loadingProductionsTable(true);
      tables(this.tenantId, this.toData(), 'productions').then((data) => {
        this.loadingProductionsTable(false);
        this.seedsProductionsTablesData(this.processTables(data));
      });
    } else {
      this.seedsProductionsTablesData(undefined);
    }

    if (this.seedSalesDisplayMode() === 'table') {
      this.loadingSalesTable(true);
      tables(this.tenantId, this.toData(), 'sales').then((data) => {
        this.loadingSalesTable(false);
        this.seedsSalesTablesData(this.processTables(data));
      });
    } else {
      this.seedsSalesTablesData(undefined);
    }

    if (this.diffusionAndImpactDisplayMode() === 'table') {
      this.loadingDiffusionAndImpactTable(true);
      tables(this.tenantId, this.toData(), 'diffusion_and_impact').then((data) => {
        this.loadingDiffusionAndImpactTable(false);
        this.diffusionAndImpactTablesData(this.processTables(data));
      });
    } else {
      this.diffusionAndImpactTablesData(undefined);
    }

    if (this.registrationAndLicensingDisplayMode() === 'table') {
      this.loadingRegistrationAndLicensingTable(true);
      tables(this.tenantId, this.toData(), 'registration_and_licensing').then((data) => {
        this.loadingRegistrationAndLicensingTable(false);
        this.registrationAndLicensingTablesData(data);
      });
    } else {
      this.registrationAndLicensingTablesData(undefined);
    }

    if (this.diffusionAndImpactSuiteDisplayMode() === 'table') {
      this.loadingDiffusionAndImpactSuiteTable(true);
      tables(this.tenantId, this.toData(), 'diffusion_and_impact_suite').then((data) => {
        this.loadingDiffusionAndImpactSuiteTable(false);
        this.diffusionAndImpactSuiteTablesData(this.processTables(data));
      });
    } else {
      this.diffusionAndImpactSuiteTablesData(undefined);
    }
  };

  private toLinkData() {
    let data: { [key: string]: {} } = { ...this.toData() };

    for (let k in data) {
      if (k === 'selected') {
        if (data[k] === 'chart') {
          data[k] = undefined;
        }
        continue;
      }
      if (k === 'support_by_sfsa' || k === 'breeding') {
        if (data[k] === 'both' || data[k] === 'any') {
          data[k] = undefined;
        }
        continue;
      }
      if (k === 'display_only_validated_data') {
        continue;
      }

      if (data[k] === false) {
        data[k] = undefined;
      }
    }

    return data;
  }

  private toData(): DashboardV2Params {
    return {
      project: extractId(this.project),
      crop_category: extractId(this.cropCategory),
      crops: deflateList(this.crops),
      crop_variety: extractId(this.cropVariety),
      countries: deflateList(this.countries),
      region: extractId(this.region),
      min_date: serializeDate(this.minDate()),
      max_date: serializeDate(this.maxDate()),
      portofolio_items: deflateList(this.portofolioItems),
      display_only_validated_data: this.displayOnlyValidatedData() === 'true',
    };
  }

  selectProductionOverview() {
    this.seedProductionDisplayMode('overview');
  }

  selectProductionTable() {
    this.seedProductionDisplayMode('table');
  }

  selectSalesOverview() {
    this.seedSalesDisplayMode('overview');
  }

  selectSalesTable() {
    this.seedSalesDisplayMode('table');
  }

  selectDiffusionAndImpactTable() {
    this.diffusionAndImpactDisplayMode('table');
  }

  selectDiffusionAndImpactOverview() {
    this.diffusionAndImpactDisplayMode('overview');
  }

  selectDiffusionAndImpactSuiteTable() {
    this.diffusionAndImpactSuiteDisplayMode('table');
  }

  selectDiffusionAndImpactSuiteOverview() {
    this.diffusionAndImpactSuiteDisplayMode('overview');
  }

  selectRegistrationAndLicensingOverview() {
    this.registrationAndLicensingDisplayMode('overview');
  }

  selectRegistrationAndLicensingTable() {
    this.registrationAndLicensingDisplayMode('table');
  }
}

export let dashboard_v2 = { name: 'dashboard_v2', viewModel: DashboardV2Screen, template: template };

ko.components.register(dashboard_v2.name, dashboard_v2);
