import * as ko from 'knockout';

import { SeedTreatmentData } from '../api/simple_api';
import { getCropSearchConfig, getSeedTreatmentSearchConfig } from '../models/helpers/search_configs';
import { NameI18nData } from 'main/api/names';
import { FormSelectFiltered, FormSelectFilteredParams } from 'main/components/form_select_filtered';

let template = require('raw-loader!../../templates/components/form_select_st.html').default;

class FormSelectST extends FormSelectFiltered<NameI18nData, SeedTreatmentData> {
    constructor(params: FormSelectFilteredParams<NameI18nData, SeedTreatmentData>) {
        super(params, {
            getFilter: value => value.crop,
            getFilterSearchConfig: getCropSearchConfig,
            getValueSearchConfig: getSeedTreatmentSearchConfig
        });
    }
}

ko.components.register('form-select-st', { viewModel: FormSelectST, template: template });
