import * as ko from 'knockout';

import { LicenseData } from '../api/simple_api';
import { readDecimal, currentYear } from 'main/utils';
import { session } from 'main/session';
import { ProducerData } from 'main/api/organizations';
import { getProducerSearchConfig, getPISearchConfig } from 'main/components/configs/search_configs';
import { CropVarietyData } from 'main/api/crop_varieties';
import { PortofolioItemData } from 'main/api/portofolio_items';
import { FormSelectSearchConfiguration } from 'main/components/form_select_search';

export class License {
    private tenantId: string;

    id = ko.observable<string>(null);
    cropVariety = ko.observable<CropVarietyData>(null).extend({
        required: true
    });
    producer = ko.observable<ProducerData>(null).extend({
        required: true
    });
    portofolioItems = ko.observableArray<PortofolioItemData>(null);
    royalty = ko.observable('').extend({
        required: true,
        number: true,
        serverError: true
    });
    startYear = ko.observable<string>(currentYear()).extend({
        required: true,
        digit: true
    });
    endYear = ko.observable<string>('').extend({
        required: true,
        digit: true,
        serverError: true
    });
    supportBySFSA = ko.observable(true);
    canEdit = ko.pureComputed(() => {
        return !this.id()
            || !this.producer()
            || (session.isAtLeastEditorFor(this.producer().country));
    });

    editUrl: string;

    producerSearchConfig = getProducerSearchConfig(this.producer, { restrictCountry: true });
    piSearchConfig: FormSelectSearchConfiguration<PortofolioItemData>;

    constructor(public data?: LicenseData) {
        if (data) {
            this.id(data.id);
            this.cropVariety(data.crop_variety);
            this.producer(data.producer);
            this.royalty(readDecimal(data.royalty));
            this.startYear(data.start_year.toString());
            this.endYear(data.end_year ? data.end_year.toString() : '');
            this.supportBySFSA(data.support_by_sfsa);
            this.portofolioItems(data.portofolio_items);
        }

        this.tenantId = session.tenant().slug;
        this.editUrl = '/licenses/' + this.id();
        this.piSearchConfig = getPISearchConfig(this.portofolioItems, {
            disableCreate: true,
            tenantId: this.tenantId
        });
    }

    toData(): LicenseData {
        return {
            id: this.id(),
            crop_variety: this.cropVariety(),
            producer: this.producer(),
            royalty: parseFloat(this.royalty()),
            start_year: parseInt(this.startYear(), 10),
            end_year: this.endYear().trim() ? parseInt(this.endYear(), 10) : null,
            support_by_sfsa: this.supportBySFSA(),
            portofolio_items: this.portofolioItems(),
        };
    }
}
